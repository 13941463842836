import React, {useState} from 'react';
import {Card, Button, Image, List, Label, Grid, Icon} from 'semantic-ui-react';
import { formatDistance } from 'date-fns';


function getOfferDateDistance (published_date) {
  let distance_str = formatDistance(new Date(published_date), new Date(), { addSuffix: true })
  if (distance_str.includes(" in")) {
    distance_str = distance_str.replace("in", "") + "ago"
  }
  distance_str = distance_str.replace("about", "")

  return distance_str
}

function getOfferTags(tags, color, expand, setExpand, is_mobile) {

  if (!tags) {
    if (color === 'pink') {
      tags = ['soft skills on apply page']
    }
    else if (color === 'green') {
      tags = ['hard skills on apply page']
    }
    else if (color === 'violet') {
      tags = [". . ."]
    }
  }

  if (!expand) {
    tags = tags.slice(0, is_mobile ? 5 : 11)
  }
  return tags.map((tag, index) => {
    return (
        <Label
            key={index}
            style={{marginBottom: 4, marginRight: 2, marginLeft: 0}}
            color={color}
            as='a'
            onClick={(e, v) => {
              if (v.children === ". . .") {
                setExpand(!expand)
              }
            }}>

          {tag}
        </Label>
    )
  })
}

const SkillLabels = ({offer, is_mobile}) => {
  const [expand, setExpand] = useState(false)
  let skill_labels = []

  skill_labels.push(getOfferTags(offer.soft_skills, 'pink', expand, setExpand, is_mobile))
  skill_labels.push(getOfferTags(offer.hard_skills, 'green', expand, setExpand, is_mobile))

  if (offer.hard_skills) {
    if ((offer.hard_skills.length > 5 && is_mobile) || ((offer.hard_skills.length > 8 && !is_mobile))) {
      skill_labels.push(getOfferTags(null, 'violet', expand, setExpand))
    }
  }

  return skill_labels

}
function get_details(offer, is_mobile) {
  let offer_date = getOfferDateDistance(offer.published)
  let offer_language = offer.language ? offer.language : null
  let offer_remote = offer.remote === "Yes" ? "Remote" : null
  let offer_job_type = !["Other"].includes(offer.job_type) ? offer.job_type : null
  let offer_location = offer.location ? offer.location : null

  let dict = [is_mobile ? offer_date : null, offer_language, offer_remote, offer_job_type, offer_location]

  return dict.filter(function(offer_data) {
    return (offer_data != null && offer_data !== '')
  }).map((offer_data, index) => {
      return (
          <Label key={index} className={"detail_label" + (is_mobile ? (index === 0 ? " date" : '') : '')} content={offer_data}/>
      )
  })
}

const DetailsAndApplyGrid = ({offer, is_mobile}) => {

  const apply_button =
      <Grid.Column width={4} style={{paddingLeft: 0}} floated='right' verticalAlign="bottom">
        <Button id="apply-button" className="apply_button_outgoing" target="_blank" href={offer.apply_type === "email" ? "mailto:" + offer.final_url : offer.final_url} primary floated="right">Apply</Button>
      </Grid.Column>

  const desktop_date =
      <Grid.Column width={is_mobile ? 2 : 4} floated="right" verticalAlign='bottom' textAlign="right">
          {/* {is_mobile ? null : apply_button} */}
            <Label key={12} className={"detail_label date"} content={getOfferDateDistance(offer.published)}/>
      </Grid.Column>

  return (
      <Grid>
        <Grid.Row columns={2}>
          <Grid.Column width={is_mobile ? 14 : 10} verticalAlign='middle' floated="left"
                       style={{color: 'rgba(128, 128, 128, 0.5)', paddingTop: '0.5em', paddingRight: 0, marginRight: "0.2em"}}>
            {get_details(offer, is_mobile)}
          </Grid.Column>
          {is_mobile ? null : desktop_date}
        </Grid.Row>
      </Grid>
  )
}

export function get_offer_card_main(offer, is_mobile) {
  let suit_case_icon = 'https://static-images123.s3.eu-west-3.amazonaws.com/no-photos.png'
  const featured_star =
      <Label size="large" corner="right" icon={ <Icon name="star"></Icon>}/>

  return (
      <Card href={`/offer/${offer.id}/${offer.title.replaceAll(" ", "-").replaceAll("/", "-")}`} key={offer.id} id="offer-card" className={(is_mobile ? "mobile": "desktop") + (offer.featured ? " featured": "")} color="purple">
        {offer.featured ? featured_star : null}
        <Card.Content style={{paddingBottom: '0.4em', paddingRight: '0.4em'}}>
          <Image alt="job offer logo" style={{marginRight: '0.9em'}} circular floated='left' size='mini'
                  src={offer.logo_base64 ? offer.logo_base64 : (offer.logo ? offer.logo : suit_case_icon)}></Image>
          <Card.Header as="h2" href={`/offer/${offer.id}/${offer.title.replaceAll(" ", "-").replaceAll("/", "-")}`} style={{fontSize: '15px'}}>{offer.title}</Card.Header>
          <Card.Meta>
            <span style={{color: 'rgb(0,0,0)'}}>{offer.company_name}</span>
          </Card.Meta>
           <Card.Description>
               <List horizontal>
                 <SkillLabels offer={offer} is_mobile={is_mobile}/>
               </List>
           </Card.Description>
          <DetailsAndApplyGrid offer={offer} is_mobile={is_mobile}/>
        </Card.Content>
      </Card>
  )
}

function get_offer_cards(offers, is_mobile) {
  return offers.map((offer) => {
    return get_offer_card_main(offer, is_mobile)
  });
}


const OfferCards = ({offers, is_mobile}) => {
  offers = offers.map((offer, index) => {
    offer['number'] = index
    return offer
  })

  return (
    <Card.Group itemsPerRow={1}>
      {get_offer_cards(offers, is_mobile)}
    </Card.Group>
  );
}

export default OfferCards;