import React, {useState} from 'react';
import {
  Container,
  Form,
  Input,
  TextArea,
  Checkbox,
  Grid,
  Radio,
  Icon,
  Button,
  Label,
  Message
} from "semantic-ui-react"

import JobPostSteps from "../components/job_post_steps";
import {get_offer_card_main} from "../components/offer_cards";
import SingleOffer from "./single_offer";

import ReCAPTCHA from "react-google-recaptcha";


const job_types = [
    {key: "f", text: "Full-Time", value: "FULL-TIME"},
    {key: "p", text: "Part-Time", value: "PART-TIME"},
    {key: "c", text: "Contract", value: "Contract"},
    {key: "fl", text: "Freelance", value: "Freelance"},
    {key: "i", text: "Internship", value: "Internship"},
    {key: "v", text: "Volunteer", value: "Volunteer"},
]

const languages = [
    {key: "eng", text: "English", value: "English"},
    {key: "fre", text: "French", value: "French"},
    {key: "ger", text: "German", value: "German"},
    {key: "por", text: "Portuguese", value: "Portuguese"},
    {key: "jap", text: "Japanese", value: "Japanese"},
    {key: "kor", text: "Korean", value: "Korean"},
    {key: "spa", text: "Spanish", value: "Spanish"},
    {key: "ita", text: "Italian", value: "Italian"},
    {key: "chi", text: "Chinese", value: "Chinese"},
    {key: "dut", text: "Dutch", value: "Dutch"},
    {key: "tur", text: "Turkish", value: "Turkish"},
    {key: "rus", text: "Russian", value: "Russian"}
]


function format_skills(data) {

  if (typeof data.soft_skills === "string" && data.soft_skills !== "") {
    data.soft_skills = data.soft_skills.split(",")
    data.soft_skills = data.soft_skills.map(s => s.trim())
  }
  if (typeof data.hard_skills === "string" && data.hard_skills !== "") {
    data.hard_skills = data.hard_skills.split(",")
    data.hard_skills = data.hard_skills.map(s => s.trim())
  }
}

const ApplyField = ({formData, handleChange}) => {
  let label_placeholders = {
    site: {label: "Site URL", placeholder: "https://yoursite.io/hiring/position"},
    email: {label: "Apply E-mail Address", placeholder: "hiring@yourcompany.io"},
    twitter: {label: "Post URL", placeholder: "https://twitter.com/username/status/123456789"},
    discord: {label: "Post URL", placeholder: "https://discord.com/channels/1234567/123456/123456"}
  }
  return (
      <Form.Field
        control={Input}
        label={label_placeholders[formData.apply_type].label}
        placeholder={label_placeholders[formData.apply_type].placeholder}
        required
        width={16}
        name="final_url"
        type={formData.apply_type === "email" ? "email" : "url"}
        value={formData.final_url}
        onChange={handleChange}
        className="form-fields"/>
  )
}


const FileUploadButton = ({handleFile}) => {
  return (
      <>
        <p className="remote-text logo-label" >Company Logo</p>
        <Button className="white-text-button" id="apply-button" as="label" htmlFor="logo-upload" type="button">
          Select a file
        </Button>
        <input type="file" id="logo-upload" onChange={handleFile} accept="image/*"/>
      </>
  )
}

// recaptcha
// Add to backedn offer requesting
// Offer paid option in Confirm section, so same parcours but you can have paid at the end.
// verify aws access key all setup in prod


const OfferForm = ({formData, setFormData, is_mobile, setStatus, handleSubmit}) => {
  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  const handleSelectChange = (e, v) => {
    setFormData({...formData, [v.name]: v.value})
  }

  const handleFile = (event) => {
    if (event.target.files[0].size < 10000000) {
      let size = event.target.files[0].size
      let selectedFile = event.target.files[0];
      let reader = new FileReader();

      reader.onload = function(event) {
        setFormData({
          ...formData,
          logo_base64: event.target.result,
          logo: selectedFile,
          file_error: false,
          file_size: size
        });
      };

      reader.readAsDataURL(selectedFile);
    }
    else {
      setFormData({ ...formData, file_error: true});
    }
  }

  return (
      <Grid>
        <Grid.Row>
          <Grid.Column width={is_mobile ? null : 3} floated="left"/>
          <Grid.Column width={is_mobile ? 16 : 10}>
            <Form className="job-post-form" onSubmit={(e, v) => {setStatus("preview")}}>
              <Form.Field
                  control={Input}
                  label="Title"
                  placeholder="Job Title"
                  required
                  width={16}
                  name="title"
                  value={formData.title}
                  onChange={handleChange}
                  className="form-fields"/>

              <Form.Field
                  control={Input}
                  label="Company Name"
                  placeholder="Company Name"
                  required
                  width={16}
                  name="company_name"
                  value={formData.company_name}
                  onChange={handleChange}
                  className="form-fields"/>

              <Form.Group unstackable>
              <Form.Select
                  fluid
                  label='Job Type'
                  options={job_types}
                  placeholder='Job Type'
                  width={8}
                  name="job_type"
                  value={formData.job_type}
                  onChange={handleSelectChange}
                  className="form-fields"/>
              <Form.Select
                  fluid
                  label='Language'
                  options={languages}
                  placeholder='Language'
                  width={8}
                  name="language"
                  value={formData.language}
                  onChange={handleSelectChange}
                  className="form-fields"/>
              </Form.Group>

              <Form.Group unstackable>
                <Form.Field
                  control={Input}
                  label="Country"
                  placeholder="Country"
                  width={8}
                  name="country"
                  value={formData.country}
                  onChange={handleChange}
                  className="form-fields"/>
                <Form.Field
                  control={Input}
                  label="State/City"
                  placeholder="State/City"
                  width={8}
                  name="location"
                  value={formData.location}
                  onChange={handleChange}
                  className="form-fields"/>
              </Form.Group>
              <Form.Field
                control={Input}
                label="Soft Skill Keywords"
                placeholder="Communication, Interpersonal, Leadership, Problem solving etc."
                width={16}
                name="soft_skills"
                value={formData.soft_skills}
                onChange={handleChange}
                className="keywords"/>
              <div className="type-gray-text form-fields" ><p>Use a comma to separate keywords</p></div>
              <Form.Field
                control={Input}
                label="Hard Skill Keywords (Recommended)"
                placeholder="Solidity, Rust, Adobe, Truffle etc."
                width={16}
                name="hard_skills"
                value={formData.hard_skills}
                onChange={handleChange}
                className="keywords"/>

              <div className="type-gray-text form-fields" ><p>Use a comma to separate keywords</p></div>
              <Form.Field
                control={TextArea}
                label='Description'
                placeholder='Tell us more about the job...'
                width={16}
                name="description"
                value={formData.description}
                onChange={handleChange}
              />
              <div className="type-gray-text form-fields" >
                <p>Markdown can be used to format the description.
                  <a rel="noreferrer" href="https://www.markdownguide.org/basic-syntax/" target="_blank"> Here </a>
                  is a guide. Alternatively we'll format the description ourselves.
                </p>
              </div>

              <div className="remote-field">
                <p className="remote-text">Remote allowed ?</p>
                <Form.Field
                    control={Checkbox}
                    name="remote"
                    toggle
                    checked={formData.remote}
                    onChange={(e, v) => setFormData({...formData, remote: v.checked })}
                  />
              </div>

              <Form.Field
                  control={FileUploadButton}
                  handleFile={handleFile}
                  inline
                  className="logo-upload-form-field"
                  error={formData.file_error ? { content: 'File size is too big, please keep it below 1 MB'} : false}
              />


              <p className="remote-text">Apply Type</p>
              <Form.Group className="apply-type-group" unstackable>
                <Form.Field
                    control={Radio}
                    label='Site'
                    name="apply_type"
                    value='site'
                    checked={formData.apply_type === 'site'}
                    onChange={handleSelectChange}
                  />

                <Form.Field
                    control={Radio}
                    label='Email'
                    name="apply_type"
                    value='email'
                    checked={formData.apply_type === 'email'}
                    onChange={handleSelectChange}
                  />

                <Form.Field
                    control={Radio}
                    name="apply_type"
                    value='twitter'
                    checked={formData.apply_type === 'twitter'}
                    onChange={(e, v) => setFormData({...formData, [v.name]: v.value})}
                  />
                  <Icon name='twitter' size="large" color="blue" />
                <Form.Field
                    control={Radio}
                    name="apply_type"
                    value='discord'
                    checked={formData.apply_type === 'discord'}
                    onChange={handleSelectChange}
                  />
                <Icon className="discord-icon" size="large" name='discord' color="blue" />
              </Form.Group>
              <ApplyField formData={formData} handleChange={handleChange}/>

              <div className="remote-field">
                <Form.Field
                  control={Checkbox}
                  name="terms_status"
                  checked={formData.terms_status}
                  onChange={(e, v) => setFormData({...formData, terms_status: v.checked })}
                  required
                />
                <p className="left-margin">I agree to the <a href="/tos" rel="noreferrer" target="_blank"> Terms and Conditions </a></p>
              </div>

             <Button
                 className="white-text-button button-preview"
                 id="apply-button"
                 size="big"
                 fluid
                 disabled={!formData.terms_status}>
               <Icon name="magnify" />
               Preview
             </Button>

            </Form>
          </Grid.Column>
          <Grid.Column width={is_mobile? null: 3} floated="right"/>
        </Grid.Row>
      </Grid>
  )
}

const CurrentStatusPage = ({is_mobile, formData, setFormData, status, setStatus, handleSubmit}) => {

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  }

  let current_status
  let offer_data = JSON.parse(JSON.stringify(formData));
  offer_data.remote = offer_data.remote ? "Yes" : "No"
  format_skills(offer_data)

  if (status === "details") {
    current_status =
        <OfferForm
            formData={formData}
            setFormData={setFormData}
            is_mobile={is_mobile}
            setStatus={setStatus}
            handleSubmit={handleSubmit}
        />
  }
  else if (status === "preview") {
    current_status =
        <>
          <Grid id="preview-offer">
            <Grid.Row>
              <Grid.Column width={2}/>
              <Grid.Column width={is_mobile ? 16 : 12}>
                <Label className="preview-label" size="huge" color="violet" content="Main View" />
                {get_offer_card_main(offer_data, is_mobile)}
                <Label className="preview-label" size="huge" color="violet" content="Offer Details View" />
                <SingleOffer
                    is_mobile={is_mobile}
                    preview={true}
                    offer_data={offer_data}
                    socials_data={{
                      name: null,
                      website: 'https://tokenjobs.io',
                      twitter: "https://twitter.com/tokenjobs",
                      lens: "https://www.lensfrens.xyz",
                      discord: "https://discord.com"}}
                />
                <Button
                    onClick={(e, v) => setStatus("confirm")}
                    size="big"
                    id="apply-button"
                    className="button-finalize"
                    attached="bottom" primary>
                  <Icon name="paper plane"/>
                  Finalize
                </Button>
              </Grid.Column>
              <Grid.Column width={2}/>
            </Grid.Row>
          </Grid>

        </>
  }
  else if (status === 'confirm') {
    const message_error =
        <Message negative id="job-post-message-error">
          <Message.Header style={{textAlign: "center"}}>There has been an error!</Message.Header>
        </Message>
    current_status =
        <Grid id="preview-offer">
          <Grid.Row>
            <Grid.Column width={2}/>
            <Grid.Column width={is_mobile ? 16 : 12}>
              <Form className="job-post-form">
                <Form.Field
                  control={Input}
                  id='form-input-control-error-email'
                  label="E-mail address"
                  placeholder="Valid Email address"
                  required
                  width={16}
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="keywords"/>
              <div className="type-gray-text form-fields" ><p>Used for modifying/deleting job offer</p></div>

                <Form.Field
                  control={Input}
                  label="Company website [optional]"
                  placeholder="https://yourcompany.io"
                  width={16}
                  type="url"
                  name="company_website"
                  value={formData.company_website}
                  onChange={handleChange}
                  className="form-fields"/>

                <Form.Field
                  control={Input}
                  label="Twitter [optional]"
                  placeholder="@yourcompany"
                  width={16}
                  name="company_twitter"
                  value={formData.company_twitter}
                  onChange={handleChange}
                  className="form-fields"/>

                <Form.Field
                  control={Input}
                  label="Lens [optional]"
                  placeholder="@yourcompany"
                  width={16}
                  name="company_lens"
                  value={formData.company_lens}
                  onChange={handleChange}
                  className="form-fields"/>
                <Form.Field
                  control={Input}
                  label="Discord [optional]"
                  placeholder="https://discord.gg/invite_code"
                  width={16}
                  name="company_discord"
                  value={formData.company_discord}
                  onChange={handleChange}
                  className="form-fields"/>
                <Form.Field
                  control={Input}
                  label=" How did you come to know about us? [optional]"
                  width={16}
                  name="discover_reason"
                  value={formData.discover_reason}
                  onChange={handleChange}
                  className="form-fields"/>

                <div id="recaptcha-div">
                  <ReCAPTCHA
                      sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
                      onChange={token => setFormData({ ...formData, recaptcha_token: token })}
                  />
                </div>
                <Button
                    onClick={handleSubmit}
                    size="big"
                    id="apply-button"
                    attached="bottom"
                    className="button-submit"
                    primary
                    // loading={formData.posting}
                    disabled={formData.recaptcha_token === null
                        || formData.recaptcha_token === ""
                        || formData.email === ""
                        || formData.email.indexOf("@") === -1
                        || formData.email.indexOf(".") === -1
                        }>
                  Submit
                </Button>
                {formData.submit_error === true ? message_error : null}
              </Form>
            </Grid.Column>
            <Grid.Column width={2}/>
          </Grid.Row>
        </Grid>
  }
  else if (status === "success") {
    current_status =
          <Grid id="preview-offer">
            <Grid.Row>
              <Grid.Column width={2}/>
              <Grid.Column width={is_mobile ? 16 : 12}>
                <Message positive id="job-post-message-success">
                  <Message.Header>Your job post was successfully submitted!</Message.Header>
                  <p>
                    Once we review it, we'll inform you via email when it's live.
                  </p>
                </Message>
                {get_offer_card_main(offer_data, is_mobile)}
              </Grid.Column>
              <Grid.Column width={2}/>
            </Grid.Row>
          </Grid>
  }

  return current_status
}

const JobPost = ({is_mobile}) => {
  const [formData, setFormData] = useState({
    title: "",
    company_name: "",
    job_type: "",
    language: "",
    location: "",
    country: "",
    soft_skills: [],
    hard_skills: [],
    description: "",
    remote: false,
    email: "",
    final_url: "",
    apply_type: "site",
    logo: "",
    logo_base64: null,
    published: Date.now() - 60000,


    company_website: null,
    company_twitter: null,
    company_lens: null,
    company_discord: null,
    discover_reason: "",
    terms_status: false,
    id: 1,

    recaptcha_token: "",

    file_error: false,
    submit_error: false,

    file_size: 0,

    posting: false

  })

  const [status, setStatus] = useState("details")

  const handleSubmit = e => {
    e.preventDefault()

    let post_data = JSON.parse(JSON.stringify(formData));
    post_data.remote = post_data.remote ? "Yes" : "No"
    delete post_data.logo_base64


    const data = new FormData();
    Object.keys(post_data).forEach(key => data.append(key, post_data[key]));
    if (formData.logo !== "") {
      data.append("logo", formData.logo, "company_logo")
    }

    setFormData({...formData, posting: true})
    fetch(process.env.REACT_APP_BASE_URL + "/job-post", {
      method: 'post',
      body: data,
    })
        .then((res) => res.json())
        .then(json => {
          if (json.message === 'success') {
            setStatus("success")
          }
          else {
            setFormData({...formData, submit_error: true, posting: false})
          }
        })
  }


  return (
      <>
        <Container text>
          <JobPostSteps status={status} setStatus={setStatus}/>
          <CurrentStatusPage
              is_mobile={is_mobile}
              formData={formData}
              setFormData={setFormData}
              status={status}
              setStatus={setStatus}
              handleSubmit={handleSubmit}
          />
        </Container>
      </>
  )
}

export default JobPost;