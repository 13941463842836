import React from 'react';


import { Container, Header, List, Segment } from 'semantic-ui-react';

const About = () => {
  return (
    <Container text>
      <div className="about-header">
        <Header className="header-margin" as="h1" textAlign="center">
           <span className="white-text">About token</span> <span className="green-text">jobs</span>
        </Header>
      </div>

      <div className="about-section">
        <p>
          tokenJobs is a one-stop solution for all your cryptocurrency, web3, and NFT job needs.
        </p>
        <p>Our mission is to
          provide a comprehensive platform where job seekers and employers can connect and explore opportunities in
          the rapidly growing blockchain and cryptocurrency industry.
        </p>
      </div>
      <div className="about-header">
        <Header as="h2" inverted content="Why?" textAlign="center"/>
      </div>
      <div className="about-section">
        <p>
          The idea for tokenJobs was born out of the frustration of a web3 native individual who struggled to find a
          single platform that consolidated all the exciting job opportunities in the crypto space.
          </p>
        <p>
          Instead of hopping
          from one website to another, tokenJobs aims to save your time and energy by bringing all the relevant job
          listings under one roof.
        </p>
      </div>

      <div className="about-header">
        <Header as="h2" inverted content="What We Offer" textAlign="center"/>
      </div>
      <div className="about-section">
        <p>
          At tokenJobs, we strive to provide a comprehensive and user-friendly platform for job seekers and
          employers in the blockchain and cryptocurrency industry. Our platform offers:
        </p>
        <List bulleted className="about-list">
          <List.Item>
            A wide range of job opportunities in the cryptocurrency, web3, and NFT space, including development,
            marketing, design, and more.
          </List.Item>
          <List.Item>
            An easy-to-use search and filter system to help you find the perfect job based on your skills,
            experience, and preferences.
          </List.Item>
          <List.Item>
            A platform for employers to post job listings and reach a targeted audience of professionals and
            enthusiasts in the blockchain and cryptocurrency industry.
          </List.Item>
          <List.Item>
            Regular updates on the latest job opportunities, ensuring you never miss out on your dream job.
          </List.Item>
        </List>
        </div>


        <div className="about-header">
          <Header as="h2" inverted content="Our Vision" textAlign="center"/>
        </div>

        <Segment className="custom-segment">
        <Header as="h3" className="custom-header">
          We believe in the power of blockchain technology and its potential to revolutionize the way we live and work.
        </Header>
        <p className="custom-paragraph">
          We are committed to supporting the growth of this industry by connecting talented individuals with the right opportunities. Join us in our mission to shape the future of the crypto world!
        </p>
      </Segment>

      <div className="about-section">
        <p>
          If you have any questions or suggestions, please feel free to{' '}
          <a href="mailto:contact@tokenjobs.io" className="green-text">
            contact us
          </a>. We'd love to hear from you!
        </p>
      </div>
    </Container>
  );
};

export default About;
