import React from 'react'
import {Pagination, Grid} from 'semantic-ui-react'

const OfferPagination = (props) => {
    return (
        <div>
          <Grid centered>
              <Pagination
                  style={{marginTop: '2em', marginBottom: props.is_mobile ? '8em' : '3em'}}
                  onPageChange={(e, data) => {
                    props.setQuery(previousState => {
                      return { ...previousState, page: data.activePage - 1 }
                    });
                  }}
                  ellipsisItem={null}
                  boundaryRange={0}
                  firstItem={null}
                  lastItem={null}
                  siblingRange={1}
                  activePage={props.page + 1}
                  totalPages={15} />
          </Grid>
        </div>
    );
}

export default OfferPagination;