import React, {useState} from "react";
import {Container} from "semantic-ui-react";
import { Helmet } from 'react-helmet';
import { useSearchParams } from "react-router-dom";

import OfferCards from "../components/offer_cards";
import OfferPagination from "../components/offer_pagination";
import OfferLoading from "../components/offer_loading";
import useFetchOffers from "../hooks/useFetchOffers";
import Layout from "../components/layout";
import SearchAndFilter from "../components/search_and_filter";
import LogoSlideshow from "../components/logo_slideshow";
import { useNavigate, useLocation } from 'react-router-dom';



const OfferPage = ({is_mobile, special_params = {}, router_fix = {}}) => {
  document.title = 'document_title' in special_params ? special_params['document_title'] : document.title
  const [offer_data, setOfferData] = useState({
    offers: [], featured_offers: [], count: 0, offer_loading: true
  });
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation()

  if (router_fix) {
    if (searchParams.get(router_fix['type']) && (searchParams.get(router_fix['type']) !== router_fix['value'])) {
      if (location.pathname !== "/") {
        navigate('/', {replace: true});
        console.log(`navigated - ${searchParams.get(router_fix['type'])} - ${router_fix['value']}`)
      }
    }
  }
  const params = Object.fromEntries([...searchParams])
  const [query, setQuery] = useState({
    page: 0,
    search_query: 'search_query' in params ? params['search_query'] : ('search_query' in special_params ? special_params['search_query'] : ''),
    title: 'title' in params ? params['title'] === 'true' : false,
    days: 'days' in params && !isNaN(params['days']) && ['1', '7', '30', '90', '180'].includes(params['days']) ? +params['days'] : ('days' in special_params ? special_params['days'] : 30),
    remote: 'remote' in params ? params['remote'] === 'true' : ('remote' in special_params ? special_params['remote'] : false),
    job_type: 'job_type' in params ? params['job_type'] : ('job_type' in special_params ? special_params['job_type'] : ''),
    language: 'language' in params ? params['language'] : '',
    search_location_query: 'search_location_query' in params ? params['search_location_query'] : ('search_location_query' in special_params ? special_params['search_location_query'] : ''),
  })

  useFetchOffers(
      setOfferData, setSearchParams, query.page, query.search_query, query.title,
      query.days, query.remote, query.job_type, query.language,
      query.search_location_query, special_params
  )
  let tags =
    <Helmet>
      <meta name="description" content={special_params['description']}/>
      <meta property="og:description" content={special_params['description']}/>
      <meta property="og:title" content={special_params['document_title']}/>
      <meta property="og:url" content={`${process.env.REACT_APP_PRINCIPAL_URL}${'canonical' in special_params ? `/${special_params['canonical']}` : ''}`}/>
      <link rel="canonical" href={`${process.env.REACT_APP_PRINCIPAL_URL}${'canonical' in special_params ? `/${special_params['canonical']}` : ''}`}/>
    </Helmet>

  return (
      <Layout offer_loading={offer_data.offer_loading} offers_length={offer_data.offers.length}
              is_mobile={is_mobile} header_data={special_params !== {} && 'header_text' in special_params ? {header_text: special_params['header_text'], header_sub_text: special_params['header_sub_text']} : null}
      >
        {tags}
        <LogoSlideshow offers={offer_data.offers} is_mobile={is_mobile}/>
        <Container text>
          <SearchAndFilter
              offers_total_count={offer_data.count}
              is_mobile={is_mobile}
              setQuery={setQuery}
              query={query}
              params={params}
          />
          <OfferLoading offerLoading={offer_data.offer_loading}/>
          <OfferCards is_mobile={is_mobile} offers={offer_data.featured_offers}/>
          <OfferCards is_mobile={is_mobile} offers={offer_data.offers}/>
          <OfferPagination page={query.page} setQuery={setQuery} is_mobile={is_mobile}/>
        </Container>
      </Layout>
  )
}

export default OfferPage;