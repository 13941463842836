import {Button} from "semantic-ui-react";
import React from "react";

const CategoryNavigation = ({category_show_more, setCategoryShowMore, is_mobile}) => {
  return (
      <nav role="navigation" className={"category-navigation" + (is_mobile ? " mobile": " desktop")}>
          <Button as='a' href="/nft" basic color='black' content='NFT' />
          <Button as='a' href="/web3-jobs" basic color='black' content='Web3' />
          <Button as='a' href="/defi" basic color='black' content='DeFi' />
          <Button as='a' href="/solidity" basic color='black' content='Solidity' />
          <Button as='a' href="/community-manager" basic color='black' content='Community Manager' />
          <Button as='a' href="/marketing" basic color='black' content='Marketing' />
          <Button as='a' href="/sales" basic color='black' content='Sales' />
          <Button as='a' href="/design" basic color='black' content='Design' />
          <Button as='a' href="/engineering" basic color='black' content='Engineering' />
          <Button as='a' href="/dao" basic color='black' content='Dao' />
          <Button as='a' href="/copywriter" basic color='black' content='Copywriter' />
              {!is_mobile &&
                  <>
                  <Button as='a' href="/finance" basic color='black' content='Finance'/>
                  <Button as='a' href="/internship" basic color='black' content='Internship' />
                  <Button as='a' href="/product-manager" basic color='black' content='Product Manager' />
                  <Button as='a' href="/rust" basic color='black' content='Rust' />
                  {/*<Button as='a' href="/non-tech" basic color='black' content='Non Tech' />*/}
                  <Button as='a' href="/compliance" basic color='black' content='Compliance' />
                  <Button as='a' href="/content-writer" basic color='black' content='Content Writer' />
                  <Button as='a' href="/social-media" basic color='black' content='Social Media' />
                  <Button as='a' href="/trading" basic color='black' content='Trading' />
                  <Button as='a' href="/remote" basic color='black' content='Remote' />
                  </>
              }

          {!category_show_more &&
              <Button color='violet' content='Show More' onClick={(e, v) => setCategoryShowMore(!category_show_more)}/>
          }

          {category_show_more &&
              <>
            <Button as='a' href="/ethereum" basic color='black' content='Ethereum'/>
            <Button as='a' href="/legal" basic color='black' content='Legal' />
            <Button as='a' href="/freelance" basic color='black' content='Freelance' />
            <Button as='a' href="/part-time" basic color='black' content='Part Time' />
            <Button as='a' href="/full-time" basic color='black' content='Full Time' />
            <Button as='a' href="/python" basic color='black' content='Python' />
            <Button as='a' href="/avalanche" basic color='black' content='Avalanche' />
            <Button as='a' href="/volunteer" basic color='black' content='Volunteer' />
            <Button as='a' href="/polygon" basic color='black' content='Polygon' />
            <Button as='a' href="/exchange" basic color='black' content='Exchange' />
            <Button as='a' href="/mining" basic color='black' content='Mining' />
            <Button as='a' href="/react" basic color='black' content='React' />
            <Button as='a' href="/frontend" basic color='black' content='Frontend' />
            <Button as='a' href="/ruby" basic color='black' content='Ruby' />
            <Button as='a' href="/translator" basic color='black' content='Translator' />
            <Button as='a' href="/java" basic color='black' content='Java' />
            <Button as='a' href="/bnb-chain" basic color='black' content='BNB Chain' />
            <Button as='a' href="/startup" basic color='black' content='Startup' />
            <Button as='a' href="/content-marketing" basic color='black' content='Content Marketing' />
            <Button as='a' href="/web3py" basic color='black' content='Web3 py' />
            <Button as='a' href="/web3js" basic color='black' content='Web3 js' />
            <Button as='a' href="/data-analyst" basic color='black' content='Data Analyst' />
            <Button as='a' href="/fantom" basic color='black' content='Fantom' />
            <Button as='a' href="/devops" basic color='black' content='DevOps' />
            <Button as='a' href="/business-development" basic color='black' content='Business Development' />
            <Button as='a' href="/cosmos" basic color='black' content='Cosmos' />
            <Button as='a' href="/game-dev" basic color='black' content='Game Dev' />
            <Button as='a' href="/gpt" basic color='black' content='GPT' />
            <Button as='a' href="/unity" basic color='black' content='Unity' />
            <Button as='a' href="/prompt-engineering" basic color='black' content='Prompt Engineering' />
            </>
          }
          {category_show_more &&
              <Button color='violet' content='Show Less' onClick={(e, v) => setCategoryShowMore(!category_show_more)}/>
          }
        </nav>
  )
}

export default CategoryNavigation