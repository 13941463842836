import { Dropdown, Icon} from 'semantic-ui-react'

function create_dropdown_items(items, setQuery, type) {
  return items.map((item) => {
    return (
        <Dropdown.Item onClick={(e, v) => {
          let values = {search_query: {search_query: ''}, language: {language: ''}, job_type: {job_type: ''}}
          values[type][type] = v.text
          setQuery(previousState => {
            return Object.assign({}, { ...previousState, page: 0 }, values[type])
          })}}
          description={item.description} selected text={item.text} value={item.text} key={item.text} />
    )})
}

function filter_dropdown_sections(items, header_content, setQuery, type) {
  return (
      <>
        <Dropdown.Header content={header_content}/>
        <Dropdown.Divider/>
        {create_dropdown_items(items, setQuery, type)}
        <Dropdown.Divider/>
      </>
  )
}

let roles = [
    {text: "Solidity"}, {text: "Product Manager"}, {text: "Community Manager"},
    {text: "Marketing"}, {text: "Data Analysis"}, {text: "Design"}
]

let contract_types = [
    {text: "Full-Time"}, {text: "Part-Time"}, {text: "Internship"},
    {text: "Freelance"}, {text: "Contract"}, {text: "Volunteer"}
]

let apply_types = [
    {text: "website"}, {text: "twitter"}, {text: "discord"}
]

let languages = [
    {text: "English"}, {text: "French"}, {text: "German"}, {text: "Portuguese"},
    {text: "Japanese"}, {text: "Chinese"}, {text: "Dutch"}, {text: "Turkish"},
    {text: "Russian"}, {text: "Korean"}, {text: "Spanish"}, {text: "Italian"}
]

const FilterDropDown = (props) => (
  <Dropdown
      compact
      basic
      inline
      text="Filter"
      floating
      labeled
      button
      scrolling
      icon={<Icon name="filter"/>}
      className='icon'
      direction="left">
    <Dropdown.Menu>
      {filter_dropdown_sections(roles, "Popular Roles", props.setQuery, "search_query")}
      {filter_dropdown_sections(contract_types, "Contract type", props.setQuery, "job_type")}
      {/*{filter_dropdown_sections(apply_types, "Apply type", props.setQuery, "apply_type")}*/}
      {filter_dropdown_sections(languages, "Language", props.setQuery, "language")}

    </Dropdown.Menu>
  </Dropdown>
)

export default FilterDropDown;

