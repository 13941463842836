import React from 'react';
import { Container, Header, List, Segment } from "semantic-ui-react"



const Contact = () => {
  return (
    <Container text>
      <div className="about-header main-header-contact">
      <Header inverted as='h2' icon textAlign='center'>
        Contact Us
      </Header>
      </div>
      <Segment>
        <p>Welcome to our Contact Page! We genuinely appreciate your interest in connecting with us</p>
        <p>Our team is always eager to listen to your questions, feedback, and suggestions. </p>
        <p>You can get in touch with us through email or Twitter, and we promise to respond as soon as possible. Thank you for reaching out! We look forward to hearing from you!</p>
      </Segment>
      <List className="margin-contact-links" style={{marginTop: '5rem'}}>
        <List.Item>
          <List.Icon name='mail'/>
          <List.Content>
            <a href='mailto:contact@tokenjobs.io'>contact@tokenjobs.io</a>
          </List.Content>
        </List.Item>
        <List.Item>
          <List.Icon name='twitter' color="blue"/>
          <List.Content>
            <a href='https://twitter.com/tokenjobs' target='_blank' rel='noopener noreferrer'>@tokenjobs</a>
          </List.Content>
        </List.Item>
      </List>
    </Container>
  );
};

export default Contact;