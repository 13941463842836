import {Segment, Container, Grid, Header, Button, Form, Icon} from 'semantic-ui-react';
import React, {useState, useEffect} from "react";

const Subscribe = ({is_mobile}) => {

  const [email, setEmail] = useState("")
  const [subscribed, setSubscribed] = useState(false)

  useEffect(() => {
  const submitForm = () => setSubscribed(true);
  document.querySelector("#subscribe-form").addEventListener('submit', submitForm);
  return () => {
    document.querySelector("#subscribe-form").removeEventListener('submit', submitForm);
  };
}, []);


  return (
      <>
        <Grid.Row id="grid-row-subscribe">
          <Grid.Column width={is_mobile ? null : 5}/>
          <Grid.Column width={is_mobile ? 16 : 6} verticalAlign="middle">
            <Form id="subscribe-form" action="https://tokenjobs.substack.com/api/v1/import/form/" method="post" target="_blank">
              <Form.Field
                  control="input"
                  id="subscribe-form-field"
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder="Type your email here"
                  width={16}
                  name="email"
                  value={email}/>
              <input type="hidden" name="first_url" value="https://tokenjobs.io"/>
              <input type="hidden" name="current_url" value="https://tokenjobs.io"/>
              <input type="hidden" name="current_referrer" value="https://tokenjobs.io"/>
              <input type="hidden" name="source" value="embed"/>
              <Button
                  fluid
                  type="submit"
                  size="large"
                  id="apply-button"
                  primary
                  disabled={subscribed}
                  >
                {subscribed ? "Subscribed!" : "Subscribe"}
              </Button>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </>
  )
}

const Footer = (props) =>  {

  return (
     <Segment id='segment-header-footer' inverted vertical>
       <Container text>
        <Grid inverted>
          <Grid.Row>
            <Grid.Column textAlign="center">
              <Header inverted as="h3" >Join our newsletter and don't miss any jobs!</Header>
            </Grid.Column>
          </Grid.Row>
          <Subscribe is_mobile={props.is_mobile}/>
          {/*<Grid.Row columns={4}>*/}
          {/*  <Grid.Column width={5} floated="left"/>*/}
          {/*  <Grid.Column style={{paddingLeft: props.is_mobile ? null : '3em'}} width={4}>*/}
          {/*    <Header inverted as='h4' content='About' />*/}
          {/*    <List link inverted>*/}
          {/*      <List.Item href="mailto:contact@tokenjobs.io" as='a'>Contact Us</List.Item>*/}
          {/*      <List.Item href='https://twitter.com/tokenJobs' as='a'>Twitter</List.Item>*/}
          {/*    </List>*/}
          {/*  </Grid.Column>*/}
          {/*  <Grid.Column style={{paddingLeft: props.is_mobile ? null : '3em'}} width={4}>*/}
          {/*    <Header inverted as='h4' content='Services' />*/}
          {/*    <List link inverted>*/}
          {/*      <List.Item href='/job-post' as='a'>Post a job</List.Item>*/}
          {/*      <List.Item as='a'>Pricing</List.Item>*/}
          {/*    </List>*/}
          {/*  </Grid.Column>*/}
          {/*  <Grid.Column width={3} floated="right"/>*/}
          {/*</Grid.Row>*/}
          <Grid.Row>
            <Grid.Column textAlign='center' style={{marginTop: '1em'}}>
              <p id="footer-message">One spot for all web3 opportunities. </p>
              <p>© 2023 token jobs
                <a className="footer-twitter" href="https://www.twitter.com/tokenjobs">
                  <Icon color="blue" name="twitter"/>
                </a>
                <a className="footer-twitter" href="https://www.linkedin.com/company/token-jobs/">
                  <Icon color="blue" name="linkedin"/>
                </a>
                <a className="footer-twitter" href="https://www.facebook.com/people/Token-Jobs/61553465815464/">
                  <Icon color="blue" name="facebook"/>
                </a>
              </p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
       </Container>
     </Segment>
  )
}

export default Footer;