import { Dropdown, Header } from 'semantic-ui-react'


const options = [
  {
    key: '24h',
    text: '24h',
    value: '1',
  },
  {
    key: '7D',
    text: '7D',
    value: '7',
  },
  {
    key: '30D',
    text: '30D',
    value: '30',
  },
  {
    key: '3M',
    text: '3M',
    value: '90',
  },
  {
    key: '6M',
    text: '6M',
    value: '180',
  }
]

const SortDropDown = ({setQuery, query}) => {
  return (
    <Header as='h4'>
      <Header.Content>
        Last {' '}
        <Dropdown
            inline
            options={options}
            defaultValue={query.days.toString()}
            direction="left"
            onChange={(e, data) => {
              setQuery(previousState => {
                    return {...previousState, days: data.value, page: 0}
                  }
              )
            }}
        />
      </Header.Content>
    </Header>
  )
}

export default SortDropDown;