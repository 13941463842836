import React from 'react';
import { Container, Header } from "semantic-ui-react"



const Tos = () => {
    return (
        <Container text>
            <Header inverted textAlign="center" className='big-size-header about-header about-header-big' as="h1">Terms of Service</Header>
            <div id="last-updated">Last Updated: [12.10.2023]</div>
            <div id="tos-intro">
              <p>Welcome to tokenjobs, an online cryptocurrency and web3 job board platform that allows users to post job offers and apply for jobs in the cryptocurrency industry.</p>

              <p>Please read these Terms of Service ("TOS", "Terms") carefully before using our website www.tokenjobs.io ("Service", "Platform", "Site") operated by tokenjobs ("us", "we", or "our"). </p>

              <p>Your access to and use of the Service is conditioned on your acceptance of and compliance with these Terms. </p>

                <p>These Terms apply to all visitors, users, and others who access or use the Service. By accessing or using the Service, you agree to be bound by these Terms. </p>

              <p>If you disagree with any part of the terms, then you may not access the Service.</p>
            </div>

          <Header as="h1" className="numbered-terms-header">
            1. Eligibility
            <Header.Subheader>
              To access and use our Service, you must be at least 18 years old and have the legal capacity to enter into these Terms. By using our Service, you represent and warrant that you meet these eligibility requirements.
            </Header.Subheader>
          </Header>

          <Header as="h1" className="numbered-terms-header">
            2. Job Posting and Validation
            <Header.Subheader>
              <p>When posting a job offer on our Platform, you agree to provide accurate, relevant, and non-misleading information about the job, including but not limited to its title, description, requirements, logo, apply information .</p>
              <p>All job postings must comply with applicable local, national, and international laws and regulations.</p>
              <p>We reserve the right, at our sole discretion, to review, edit, or remove any job postings that do not meet our guidelines, are inappropriate, or violate these Terms, or are suspected to be posted without proper authorization. </p>
              <p>By submitting a job posting, you grant us a non-exclusive, royalty-free, worldwide license to use, display, reproduce, and distribute your job posting on our Platform and on any other channels we deem appropriate.</p>
              <p>You are not allowed to post job offers without proper authorization or to share defamatory or false information about a company. </p>
              <p>We reserve the right to verify the authenticity of job postings, and if we suspect any violation, we may disclose the user's information to the concerned company or appropriate legal authorities.</p>
            </Header.Subheader>
          </Header>

          <Header as="h1" className="numbered-terms-header">
            3. Featured Job Offers
            <Header.Subheader>
              <p>We offer a paid service to feature job offers, which will give your job posting higher visibility on our Platform. By paying for this service, you agree to abide by our guidelines and these Terms.</p>
              <p>We reserve the right to refuse featuring any job offer that violates our guidelines or these Terms, in which case we will refund the payment for the featured service. </p>
            </Header.Subheader>
          </Header>

          <Header as="h1" className="numbered-terms-header">
            4. Payments and Refunds
            <Header.Subheader>
              <p>All payments for services provided by our Platform, including featured job offers, must be made in a supported fiat currency or cryptocurrency. We do not accept any other forms of payment. By making a payment, you represent and warrant that you have the legal right to use the chosen payment method for the transaction.</p>
              <p>Refunds for featured job offers will only be provided if we refuse to feature your job offer due to non-compliance with our guidelines or these Terms. All other payments are non-refundable. </p>
            </Header.Subheader>
          </Header>

          <Header as="h1" className="numbered-terms-header">
            5. Intellectual Property
            <Header.Subheader>
              <p>The content and materials on our Platform, including but not limited to text, graphics, logos, and code, are the property of tokenjobs and are protected by copyright, trademark, and other intellectual property laws.</p>
              <p>You may not use or reproduce any content or materials from our Platform without our express written permission.</p>
            </Header.Subheader>
          </Header>

          <Header as="h1" className="numbered-terms-header">
            6. Disclaimer and Limitation of Liability
            <Header.Subheader>
              <p>Our Platform is provided on an "as is" and "as available" basis. We make no representations or warranties of any kind, express or implied, about the completeness, accuracy, reliability, suitability, or availability of the Platform or the information, products, services, or related graphics contained on the Platform for any purpose. Any reliance you place on such information is strictly at your own risk. </p>
              <p>In no event will we be liable for any loss or damage, including without limitation, indirect or consequential loss or damage, or any loss or damage whatsoever arising from loss of data or profits arising out of, or in connection with, the use of our Platform.</p>
            </Header.Subheader>
          </Header>

          <Header as="h1" className="numbered-terms-header">
            7. Indemnification
            <Header.Subheader>
              <p>You agree to indemnify, defend, and hold harmless tokenjobs, its affiliates, officers, directors, employees, agents, and licensors from and against any and all claims, liabilities, damages, losses, or expenses, including reasonable attorneys' fees and costs, arising out of or in any way connected with your access to or use of our Platform.</p>
            </Header.Subheader>
          </Header>

          <Header as="h1" className="numbered-terms-header">
            8. Termination
            <Header.Subheader>
              <p>We may terminate or suspend your access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms. Upon termination, your right to use the Service will immediately cease.</p>
            </Header.Subheader>
          </Header>

          <Header as="h1" className="numbered-terms-header">
            9. Governing Law and Jurisdiction
            <Header.Subheader>
              <p>These Terms shall be governed and construed in accordance with the laws of the European Union, without regard to its conflict of law provisions. </p>
              <p>Any disputes arising out of or relating to these Terms or your use of our Service will be subject to the exclusive jurisdiction of the courts located in the European Union.</p>
            </Header.Subheader>
          </Header>

          <Header as="h1" className="numbered-terms-header">
            10. Prohibited Use of Website Information and Activities
            <Header.Subheader>
              <p>You are strictly prohibited from using, scraping/crawling, appropriating, framing, or otherwise extracting or reproducing any information or content from our website without our express written consent.</p>
              <p>Additionally, you are not allowed to attempt to tamper with our systems, use another person's information for spamming purposes, or submit unlawful material that may be deemed inappropriate.</p>
              <p>Unauthorized use of our website's information, content, or engagement in prohibited activities may result in legal action, including but not limited to claims for copyright infringement, trademark violations, or other applicable laws and regulations.</p>
              <p>By using our website, you agree to adhere to these restrictions and acknowledge that any violation of this clause may lead to the suspension or termination of your access to our website and potential legal consequences.</p>
            </Header.Subheader>
          </Header>

          <Header as="h1" className="numbered-terms-header">
            11. Severability
            <Header.Subheader>
              <p>Should any provision (or portion thereof) of these Terms be deemed invalid, unlawful, or unenforceable, the affected provision or part shall be excluded, while the remaining terms, conditions, and provisions shall maintain their validity and enforceability to the maximum extent permitted by law.</p>
            </Header.Subheader>
          </Header>

          <Header as="h1" className="numbered-terms-header">
            12. Changes to the Terms
            <Header.Subheader>
              <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time. We will provide a notice of any significant changes on our Platform.</p>
              <p>By continuing to access or use our Service after those revisions become effective, you agree to be bound by the revised terms.</p>
            </Header.Subheader>
          </Header>

          <Header as="h1" className="numbered-terms-header">
            13. Contact Us
            <Header.Subheader>
              <p>If you have any questions about these Terms, please contact us <a href="/contact"> here </a></p>
            </Header.Subheader>
          </Header>


        </Container>
    )
}

export default Tos;