import React, {useState, useEffect} from 'react';
import {Container, Dimmer, Image, Label, Loader, Segment, Grid, Button, Icon} from "semantic-ui-react"
import {useParams} from "react-router-dom";
import ReactMarkdown from 'react-markdown'
import { Helmet } from 'react-helmet';


const SingleOfferLoading = ({is_loading}) =>  {
  if (is_loading) {
    return (
        <Segment>
          <Dimmer active inverted>
            <Loader size='large'>Loading</Loader>
          </Dimmer>
          <Image alt="loading offer 1" src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
          <Image alt="loading offer 2" src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
          <Image alt="loading offer 3" src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
          <Image alt="loading offer 4" src='https://react.semantic-ui.com/images/wireframe/paragraph.png' />
        </Segment>
    )
  }
}


const SkillLabels = ({skills, color}) => {
  if (skills) {
    return skills.map((skill, index) => {
      return (
          <Label
              style={{marginRight: 2, marginLeft: 0, marginTop: 4}} color={color} as='a'
              content={skill} key={index} size="large">
          </Label>
      )
    })
  }
}

function format_location (location, country) {
  // if no location, check if country, if country return country, else nothing
  // if not country, since we know location exists, we return location.
  // Then if both exist, we return a formatted one

  if (!location) {
    if (country) {
      return country
    }
  }
  if (!country) {
    return location
  }

  if (location.indexOf(country)) {
    return location
  }
  else {
    return location + ', ' + country
  }
}

const OfferPage = ({data, is_loading, is_mobile, socials, preview}) => {
  let suit_case_icon = 'https://static-images123.s3.eu-west-3.amazonaws.com/no-photos.png'
  let lens_icon = 'https://static-images123.s3.eu-west-3.amazonaws.com/lens-logo.png'
  if (!is_loading) {
    document.title = `${data.title.replaceAll("-", " ")} at ${data.company_name} - token jobs`
    return (
        <>
          <div id="offer-title"><h1>{data.title}</h1></div>
          <Grid>
            <Grid.Row id="grid-row-logo-company_name" columns={2}>
              <Grid.Column id="offer-logo" width={is_mobile ? 2 : 1}>
                <Image alt="company logo" style={{marginRight: '0.9em'}} size='mini'
                       src={data.logo_base64 ? data.logo_base64 : (data.logo ? data.logo : suit_case_icon)}></Image>
              </Grid.Column>
              <Grid.Column id="offer-company_name" verticalAlign="middle" floated="left">
                {data.company_name}
              </Grid.Column>
            </Grid.Row>
            {socials &&
                <Grid.Row>
                  <Grid.Column className="socials-column">
                    {socials.website && socials.website !== "null" && <a target="_blank" href={socials.website} rel="noreferrer"><Icon size="big" name="globe" color="black"/></a>}
                    {socials.twitter && socials.twitter !== "null" && <a target="_blank" href={"https://twitter.com/" + socials.twitter.replace("@", "")} rel="noreferrer"><Icon size="big" name="twitter" color="blue"/></a>}
                    {socials.linkedin && socials.linkedin !== "null" && <a target="_blank" href={"https://www.linkedin.com/company/" + socials.linkedin} rel="noreferrer"><Icon size="big" name="linkedin" color="blue"/></a>}
                    {socials.discord && socials.discord !== "null" && <a target="_blank" href={socials.discord} rel="noreferrer"><Icon className="discord-icon" size="big" name="discord" color="blue"/> </a>}
                    {socials.lens && socials.lens !== "null" && <a target="_blank" href={"https://www.lensfrens.xyz/" + socials.lens.replace("@", "") + ".lens"} rel="noreferrer"><Image alt="lens logo" inline size="mini" src={lens_icon}/></a>}
                  </Grid.Column>
                </Grid.Row>
            }
          </Grid>
          <Grid>
            <Grid.Row columns={2}>
              <Grid.Column width={is_mobile? null : 4}>
                <div id="offer-other">
                  <span className="type-gray-text">Remote</span>
                  <p>{data.remote ? data.remote : "?"}</p>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div id="offer-other">
                  <span className="type-gray-text">Language</span>
                  <p>{data.language}</p>
                </div>
              </Grid.Column>
            </Grid.Row>
            <Grid.Row columns={2}>
              <Grid.Column width={is_mobile? null : 4}>
                <div id="offer-other">
                  <span className="type-gray-text">Type</span>
                  <p>{data.job_type}</p>
                </div>
              </Grid.Column>
              <Grid.Column>
                <div id="offer-other">
                  <span className="type-gray-text">Location</span>
                  <p>{format_location(data.location, data.country)}</p>
                </div>
              </Grid.Column>
            </Grid.Row>
          </Grid>

          <div id="offer-other">
            <span className="type-gray-text">Soft Skills</span>
            <div><SkillLabels skills={data.soft_skills} color="pink"/></div>
          </div>
          <div id="offer-other">
            <span className="type-gray-text">Hard Skills</span>
            <div><SkillLabels skills={data.hard_skills} color="green"/></div>
          </div>

          <ReactMarkdown>{data.description}
          </ReactMarkdown>
          <Button
              as="a"
              size="big"
              id="apply-button"
              className="button-single_offer_page apply_button_outgoing"
              target="_blank" href={data.apply_type === "email" ? "mailto:" + data.final_url : data.final_url}
              attached="bottom"
              primary
              disabled={preview}>Apply</Button>

          <p className="type-gray-text">
            Good luck with {data.company_name}! We hope you find a perfect match!
            We'd appreciate if you mention us to them
          </p>
          <div className="single-offer-end"></div>
        </>
    )
  }
}

const SingleOffer = ({is_mobile, preview=false, offer_data= {}, socials_data = {name: null, website: null, twitter: null, lens: null, discord: null}}) => {
  const { offer_id, offer_title } = useParams()
  const [single_offer_data, setSingleOfferData] = useState({data: offer_data, is_loading: !preview})
  const [socials, setSocials] = useState(socials_data)

  useEffect(() => {
    if (preview !== true) {
      let offer_url = offer_title ? `${offer_id}/${offer_title}`: offer_id
      fetch(`${process.env.REACT_APP_BASE_URL}/offer/${offer_url}`)
          .then((res) => res.json())
          .then((json) => {
            setSingleOfferData({data: json, is_loading: false})
          })

      fetch(`${process.env.REACT_APP_BASE_URL}/get-socials/${offer_id}`)
          .then((res) => res.json())
          .then((json) => setSocials(json))
      }
    }, [])

  return (
    <>
      {"title" in single_offer_data.data &&
        <Helmet>
          <link rel="canonical" href={`${process.env.REACT_APP_PRINCIPAL_URL}/offer/${offer_id}/${encodeURIComponent(single_offer_data.data.title.replaceAll(" ", "-").replaceAll("/", "-"))}`}/>
          <meta name="description" content={`${single_offer_data.data.company_name} is looking to hire a ${single_offer_data.data.title}. ${single_offer_data.data.job_type !== null && single_offer_data.data.job_type !== "" ? `This is a ${single_offer_data.data.job_type} position`: ''} ${single_offer_data.data.remote !== null && single_offer_data.data.remote.toUpperCase() === "YES" ? "that is fully remote, allowing you to work all over the world" : ""}`}/>
          <meta property="og:url" content={`${process.env.REACT_APP_PRINCIPAL_URL}/offer/${offer_id}/${single_offer_data.data.title.replaceAll(" ", "-").replaceAll("/", "-")}`}/>
          <meta property="og:description" content={`${single_offer_data.data.company_name} is looking to hire a ${single_offer_data.data.title}. ${single_offer_data.data.job_type !== null && single_offer_data.data.job_type !== "" ? `This is a ${single_offer_data.data.job_type} position`: ''} ${single_offer_data.data.remote !== null && single_offer_data.data.remote.toUpperCase() === "YES" ? "that is fully remote, allowing you to work all over the world" : ""}`}/>
          <meta property="og:title" content={`${single_offer_data.data.title.replaceAll("-", " ")} at ${single_offer_data.data.company_name} - token jobs`}/>
        </Helmet>
      }
      <Container>
        <Grid>
          <Grid.Row>
            <Grid.Column width={2}/>
            <Grid.Column width={is_mobile ? 16 : 12}>
              <SingleOfferLoading is_loading={single_offer_data.is_loading}/>
              <OfferPage data={single_offer_data.data}
                         is_loading={single_offer_data.is_loading}
                         is_mobile={is_mobile}
                         socials={socials}
                         preview={preview}/>
            </Grid.Column>
            <Grid.Column width={2}/>
          </Grid.Row>
        </Grid>

      </Container>
    </>
  )
}

export default SingleOffer;