import React from 'react';
import { createRoot } from "react-dom/client";

import 'semantic-ui-css/semantic.min.css';
import './css/custom.css'
import './css/typewriter_effect.css'
import './css/search_and_filter.css'
import './css/single_offer.css'
import  './css/job_post.css'
import './css/job_delete.css'
import './css/about_contact.css'
import './css/tos.css'

import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";


import Layout from "./components/layout";

import useCheckMobileScreen from "./hooks/checkMobileScreen";

import About from "./pages/about";
import OfferPage from "./pages/offers";
import JopPost from "./pages/job_post"
import SingleOffer from "./pages/single_offer";
import JobDelete from "./pages/job_delete";
import Contact from "./pages/contact";
import Tos from './pages/tos'


const App = () => {
  let is_mobile = useCheckMobileScreen()
  const currentDate = new Date();
  const currentMonthAbbreviation = currentDate.toLocaleString('default', { month: 'short' });

  let params = {
    web3_jobs: {
      days: 180,
      document_title: 'Web3 Jobs 2023 | token jobs',
      description: "Find Web3 jobs and work with companies and startups in the blockchain sector",
      canonical: "web3-jobs",
      header_text: "Web3 Jobs",
      header_sub_text: "Browse and find Web3 Jobs from Blockchain startups and companies"
    },
    internship: {
      days: 180,
      job_type: "internship",
      document_title: `Web3 Internship - ${currentMonthAbbreviation} 2023`,
      description: "Web3 internship in crypto companies and startups in the blockchain sector",
      canonical: "internship",
      header_text: "Web3 Internship",
      header_sub_text: "Find a Web3 internship from the best Blockchain startups and companies"
    },
    defi: {
      days: 180,
      search_query: "defi",
      document_title: `DeFi Jobs - ${currentMonthAbbreviation} 2023`,
      description: "DeFi jobs at crypto companies and startups in the blockchain sector. Solana DeFi jobs and Ethereum DeFi jobs",
      canonical: "defi",
      header_text: "DeFi Jobs",
      header_sub_text: "Find DeFi jobs from the best Blockchain startups and companies"
    },
    nft: {
      days: 180,
      search_query: "nft",
      document_title: `NFT Jobs - ${currentMonthAbbreviation} 2023`,
      description: "NFT Jobs in crypto companies and startups in the blockchain sector. Find your next Remote NFT job, NFT internship, NFT Designer or NFT engineering role. ",
      canonical: "nft",
      header_text: "NFT Jobs",
      header_sub_text: "Find NFT jobs from the best Blockchain startups and companies"
    },
    base: {
      document_title: 'Web3, Crypto and Blockchain jobs - token jobs',
      description: 'token jobs is the best Web3 and Crypto job board with over 10,000 job offers for positions such as Web3 Developer, Blockchain Developer, Defi Developer and much more.',
    },
    about: {
      document_title: 'All web3 and crypto jobs in one place - token jobs',
      description: 'We believe in the revolutionary power of blockchain technology and are committed to help grow this industry by connecting talented individuals with the right opportunities.',
      canonical: 'about',
    },
    job_post: {
      document_title: 'Post a web3 job - token jobs',
      description: 'token jobs is the best Web3 and Crypto job board with over 10,000 job offers for positions such as Web3 Developer, Blockchain Developer, Defi Developer and much more.',
      canonical: 'job-post',
    },
    contact: {
      document_title: 'Contact us - token jobs',
      description: 'Contact token jobs. Reach out to us via email or twitter down below.',
      canonical: 'contact',
    },
    terms_of_service: {
      document_title: 'Terms of service - token jobs',
      description: 'Terms of service for the token jobs website',
      canonical: 'terms-of-service',
    },
    job_delete: {
      document_title: 'Modify your web3 job - token jobs',
      description: 'token jobs is the best Web3 and Crypto job board with over 10,000 job offers for positions such as Web3 Developer, Blockchain Developer, Defi Developer and much more.',
      canonical: 'job-delete',
    },
    no_page: {
      document_title: 'Page not found - token jobs',
      description: 'token jobs is the best Web3 and Crypto job board with over 10,000 job offers for positions such as Web3 Developer, Blockchain Developer, Defi Developer and much more.',
      canonical: '',
    },

    web3_jobs_sydney: {
      days: 180,
      search_location_query: "Sydney",
      document_title: `Web3 Jobs in Sydney`,
      description: "Web3 Jobs in Sydney Australia, at crypto companies and startups in the blockchain sector. Find your next Remote Web3 job, Web3 internship, Web3 engineering role. ",
      canonical: "jobs-sydney",
      header_text: "Web3 Jobs in Sydney, Australia",
      header_sub_text: "Find Web3 Jobs from the best Blockchain startups and companies"
    },

    solidity: {
      days: 180,
      search_query: "Solidity",
      document_title: `Web3 Solidity Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Solidity Jobs at crypto companies and startups in the blockchain sector. Find your next Remote Solidity job, Solidity internship, Solidity engineering role. ",
      canonical: "solidity",
      header_text: "Web3 Solidity Jobs",
      header_sub_text: "Find Web3 Solidity Jobs from the best Blockchain startups and companies"
    },

    community_manager: {
      days: 180,
      search_query: "Community Manager",
      document_title: `Web3 Community Manager Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Community Manager jobs at crypto companies and startups in the blockchain sector. Find your next Remote Community Manager job, Community Manager internship, and more.",
      canonical: "community-manager",
      header_text: "Web3 Community Manager Jobs",
      header_sub_text: "Find Web3 Community Manager Jobs from the best Blockchain startups and companies"
    },

    marketing: {
      days: 180,
      search_query: "Marketing",
      document_title: `Web3 Marketing Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Marketing roles at crypto companies and startups in the blockchain sector. Find your next Remote Marketing job, Marketing internship, and more.",
      canonical: "marketing",
      header_text: "Web3 Marketing Jobs",
      header_sub_text: "Find Web3 Marketing Jobs from the best Blockchain startups and companies"
    },

    sales: {
      days: 180,
      search_query: "Sales",
      document_title: `Web3 Sales Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Sales positions at crypto companies and startups in the blockchain sector. Find your next Remote Sales job, Sales internship, and more.",
      canonical: "sales",
      header_text: "Web3 Sales Jobs",
      header_sub_text: "Find Web3 Sales Jobs from the best Blockchain startups and companies"
    },

    design: {
      days: 180,
      search_query: "Design",
      document_title: `Web3 Design Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Design roles at crypto companies and startups in the blockchain sector. Find your next Remote Design job, Design internship, and more.",
      canonical: "design",
      header_text: "Web3 Design Jobs",
      header_sub_text: "Find Web3 Design Jobs from the best Blockchain startups and companies"
    },

    engineering: {
      days: 180,
      search_query: "Engineering",
      document_title: `Web3 Engineering Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Engineering roles at crypto companies and startups in the blockchain sector. Find your next Remote Engineering job, Engineering internship, and more.",
      canonical: "engineering",
      header_text: "Web3 Engineering Jobs",
      header_sub_text: "Find Web3 Engineering Jobs from the best Blockchain startups and companies"
    },

    dao: {
      days: 180,
      search_query: "Dao",
      document_title: `Web3 Dao Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Dao roles at crypto companies and startups in the blockchain sector. Find your next Remote Dao job, Dao internship, and more.",
      canonical: "dao",
      header_text: "Web3 Dao Jobs",
      header_sub_text: "Find Web3 Dao Jobs from the best Blockchain startups and companies"
    },

    copywriter: {
      days: 180,
      search_query: "Copywriting",
      document_title: `Web3 Copywriting Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Copywriting positions at crypto companies and startups in the blockchain sector. Find your next Remote Copywriting job, Copywriting internship, and more.",
      canonical: "copywriting",
      header_text: "Web3 Copywriting Jobs",
      header_sub_text: "Find Web3 Copywriting Jobs from the best Blockchain startups and companies"
    },

    finance: {
      days: 180,
      search_query: "Finance",
      document_title: `Web3 Finance Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Finance positions at crypto companies and startups in the blockchain sector. Find your next Remote Finance job, Finance internship, and more.",
      canonical: "finance",
      header_text: "Web3 Finance Jobs",
      header_sub_text: "Find Web3 Finance Jobs from the best Blockchain startups and companies"
    },

    product_manager: {
      days: 180,
      search_query: "Product Manager",
      document_title: `Web3 Product Manager Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Product Manager jobs at crypto companies and startups in the blockchain sector. Lead product vision at top Web3 companies.",
      canonical: "product-manager",
      header_text: "Web3 Product Manager Jobs",
      header_sub_text: "Find Web3 Product Manager Jobs at leading Blockchain companies"
    },

    rust: {
      days: 180,
      search_query: "Rust",
      document_title: `Web3 Rust Developer Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Rust developer jobs at crypto companies and startups in the blockchain sector. Join the next-gen of Rust developers in the blockchain realm.",
      canonical: "rust",
      header_text: "Web3 Rust Developer Jobs",
      header_sub_text: "Find Web3 Rust Development Jobs in the blockchain industry"
    },

    // non_tech: {
    //   days: 180,
    //   search_query: "Non Tech",
    //   document_title: `Web3 Non-Tech Jobs - ${currentMonthAbbreviation} 2023`,
    //   description: "Web3 non-technical roles at crypto companies and startups in the blockchain sector. Explore a variety of roles outside of technical development.",
    //   canonical: "non-tech",
    //   header_text: "Web3 Non-Tech Jobs",
    //   header_sub_text: "Find a wide range of Non-Tech Jobs in the Web3 space"
    // },

    compliance: {
      days: 180,
      search_query: "Compliance",
      document_title: `Web3 Compliance Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Compliance roles ensuring regulations are followed at crypto companies and startups. Be the backbone of ethical operations in blockchain.",
      canonical: "compliance",
      header_text: "Web3 Compliance Jobs",
      header_sub_text: "Ensure Web3 operations are compliant and ethical"
    },

    content_writer: {
      days: 180,
      search_query: "Content Writer",
      document_title: `Web3 Content Writer Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 content writing roles at crypto companies and startups. Craft the narrative of the next blockchain revolution.",
      canonical: "content-writer",
      header_text: "Web3 Content Writer Jobs",
      header_sub_text: "Shape the Web3 narrative with your writing skills"
    },

    social_media: {
      days: 180,
      search_query: "Social Media",
      document_title: `Web3 Social Media Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 social media roles at crypto companies and startups. Amplify the blockchain message across platforms.",
      canonical: "social-media",
      header_text: "Web3 Social Media Jobs",
      header_sub_text: "Amplify the blockchain revolution on social media"
    },

    trading: {
      days: 180,
      search_query: "Trading",
      document_title: `Web3 Trading Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 trading positions at crypto exchanges and startups. Dive deep into the world of crypto trading.",
      canonical: "trading",
      header_text: "Web3 Trading Jobs",
      header_sub_text: "Dive deep into crypto trading with the best in the industry"
    },

    remote: {
      days: 180,
      remote: "true",
      document_title: `Web3 Remote Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 remote positions at crypto companies and startups. Work from anywhere in the world on the next big thing in blockchain.",
      canonical: "remote",
      header_text: "Web3 Remote Jobs",
      header_sub_text: "Work on Web3 projects from any corner of the world"
    },

    ethereum: {
      days: 180,
      search_query: "Ethereum",
      document_title: `Ethereum Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Ethereum jobs at crypto companies and startups in the blockchain sector. Engage with the leading smart contract platform.",
      canonical: "ethereum",
      header_text: "Ethereum Jobs",
      header_sub_text: "Dive into the world of Ethereum with the best in the industry"
    },

    legal: {
      days: 180,
      search_query: "Legal",
      document_title: `Web3 Legal Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Legal jobs ensuring the lawful operation of crypto companies and startups. Safeguard the next-gen blockchain initiatives.",
      canonical: "legal",
      header_text: "Web3 Legal Jobs",
      header_sub_text: "Become the legal backbone of groundbreaking Web3 projects"
    },

    freelance: {
      days: 180,
      job_type: "Freelance",
      document_title: `Web3 Freelance Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Freelance jobs at crypto companies and startups. Flexibility meets the world of blockchain.",
      canonical: "freelance",
      header_text: "Web3 Freelance Jobs",
      header_sub_text: "Flexibility and passion combine in Web3 Freelance roles"
    },

    part_time: {
      days: 180,
      job_type: "Part-Time",
      document_title: `Web3 Part-Time Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Part-Time jobs offering flexibility at crypto companies and startups. Join the blockchain revolution on your terms.",
      canonical: "part-time",
      header_text: "Web3 Part-Time Jobs",
      header_sub_text: "Dive into blockchain, part-time"
    },

    full_time: {
      days: 180,
      job_type: "Full-Time",
      document_title: `Web3 Full-Time Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Full-Time positions at crypto companies and startups. Dedicate your skills to the world of blockchain.",
      canonical: "full-time",
      header_text: "Web3 Full-Time Jobs",
      header_sub_text: "Commit to the future with full-time Web3 roles"
    },

    python: {
      days: 180,
      search_query: "Python",
      document_title: `Web3 Python Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Python developer jobs at crypto companies and startups. Code with one of the most loved programming languages.",
      canonical: "python",
      header_text: "Web3 Python Jobs",
      header_sub_text: "Craft the future of blockchain with Python"
    },

    avalanche: {
      days: 180,
      search_query: "Avalanche",
      document_title: `Avalanche Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Avalanche jobs in the rapidly growing Avalanche ecosystem. Explore the next-gen blockchain platforms.",
      canonical: "avalanche",
      header_text: "Avalanche Jobs",
      header_sub_text: "Embrace the Avalanche revolution"
    },

    volunteer: {
      days: 180,
      job_type: "Volunteer",
      document_title: `Web3 Volunteer Positions - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Volunteer opportunities to give back to the crypto and blockchain communities. Make an impact.",
      canonical: "volunteer",
      header_text: "Web3 Volunteer Positions",
      header_sub_text: "Lend your skills to the decentralized world"
    },

    polygon: {
      days: 180,
      search_query: "Polygon",
      document_title: `Polygon Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 jobs in the scalable and interoperable Polygon ecosystem. Join the multi-chain future.",
      canonical: "polygon",
      header_text: "Polygon Jobs",
      header_sub_text: "Innovate on the Polygon platform"
    },

    exchange: {
      days: 180,
      search_query: "Exchange",
      document_title: `Web3 Exchange Roles - ${currentMonthAbbreviation} 2023`,
      description: "Web3 roles at crypto exchanges. Be at the heart of the crypto trading ecosystem.",
      canonical: "exchange",
      header_text: "Web3 Exchange jobs",
      header_sub_text: "Power the trading of the future"
    },

    mining: {
      days: 180,
      search_query: "Mining",
      document_title: `Web3 Mining Positions - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Mining roles at forefront of crypto mining operations. Harness the power of blockchain computation.",
      canonical: "mining",
      header_text: "Web3 Mining Positions",
      header_sub_text: "Fuel the crypto world with mining expertise"
    },

    react: {
      days: 180,
      search_query: "React",
      document_title: `Web3 React Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 React jobs at crypto companies and startups. Build next-gen web applications.",
      canonical: "react",
      header_text: "Web3 React Jobs",
      header_sub_text: "Shape the Web3 interface with React"
    },

    frontend: {
      days: 180,
      search_query: "Frontend",
      document_title: `Web3 Frontend Developer Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Frontend developer roles. Create the user experiences for the decentralized web.",
      canonical: "frontend",
      header_text: "Web3 Frontend Developer Jobs",
      header_sub_text: "Design the face of the Web3 world"
    },

    ruby: {
      days: 180,
      search_query: "Ruby",
      document_title: `Web3 Ruby Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Ruby roles. Code with elegance in the crypto space.",
      canonical: "ruby",
      header_text: "Web3 Ruby Jobs",
      header_sub_text: "Drive blockchain innovation with Ruby"
    },

    translator: {
      days: 180,
      search_query: "Translator",
      document_title: `Web3 Translator Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Translator jobs. Break language barriers in the decentralized ecosystem.",
      canonical: "translator",
      header_text: "Web3 Translator Jobs",
      header_sub_text: "Connect global crypto communities"
    },

    java: {
      days: 180,
      search_query: "Java",
      document_title: `Web3 Java Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Java jobs at crypto companies and startups. Harness the power of one of the most versatile programming languages.",
      canonical: "java",
      header_text: "Web3 Java Jobs",
      header_sub_text: "Develop robust applications in the Web3 space with Java"
    },

    bnb_chain: {
      days: 180,
      search_query: "BNB Chain",
      document_title: `Web3 BNB Chain Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 positions in the dynamic BNB Chain ecosystem. Be part of the rapidly expanding Binance universe.",
      canonical: "bnb-chain",
      header_text: "Web3 BNB Chain Jobs",
      header_sub_text: "Empower the Binance Smart Chain's future"
    },

    startup: {
      days: 180,
      search_query: "Startup",
      document_title: `Web3 Startup Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 roles at budding crypto startups. Join early and shape the future.",
      canonical: "startup",
      header_text: "Web3 Startup Jobs",
      header_sub_text: "Drive innovation from the ground up"
    },

    content_marketing: {
      days: 180,
      search_query: "Content Marketing",
      document_title: `Web3 Content Marketing Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Content Marketing positions to define and enhance brand voices in the crypto space.",
      canonical: "content-marketing",
      header_text: "Web3 Content Marketing Jobs",
      header_sub_text: "Shape the voice of crypto brands"
    },

    web3_py: {
      days: 180,
      search_query: "Web3.py",
      document_title: `Web3 py Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 py jobs specializing in the Python Web3 SDK. Integrate blockchain functionalities seamlessly with Python.",
      canonical: "web3-py",
      header_text: "Web3 py Jobs",
      header_sub_text: "Power Web3 with the Python touch"
    },

    web3_js: {
      days: 180,
      search_query: "Web3.js",
      document_title: `Web3 js Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 js jobs specializing in the JavaScript Web3 SDK. Make decentralized web applications come alive.",
      canonical: "web3-js",
      header_text: "Web3 js Jobs",
      header_sub_text: "Shape the decentralized web with JS"
    },

    data_analyst: {
      days: 180,
      search_query: "Data Analyst",
      document_title: `Web3 Data Analyst Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Data Analyst jobs to crunch numbers and draw insights in the crypto realm.",
      canonical: "data-analyst",
      header_text: "Web3 Data Analyst Jobs",
      header_sub_text: "Decode the patterns of the Web3 world"
    },

    fantom: {
      days: 180,
      search_query: "Fantom",
      document_title: `Web3 Fantom Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 roles in the high-performance Fantom ecosystem. Dive into a leading layer-1 platform.",
      canonical: "fantom",
      header_text: "Web3 Fantom Jobs",
      header_sub_text: "Explore the Fantom frontier"
    },

    devops: {
      days: 180,
      search_query: "DevOps",
      document_title: `Web3 DevOps Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 DevOps jobs to ensure seamless development and operations in crypto projects.",
      canonical: "devops",
      header_text: "Web3 DevOps Jobs",
      header_sub_text: "Orchestrate the Web3 infrastructure"
    },

    business_development: {
      days: 180,
      search_query: "Business Development",
      document_title: `Web3 Business Development Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Business Development roles to drive growth and strategic partnerships in the blockchain space.",
      canonical: "business-development",
      header_text: "Web3 Business Development Jobs",
      header_sub_text: "Forge alliances in the Web3 domain"
    },

    cosmos: {
      days: 180,
      search_query: "Cosmos",
      document_title: `Web3 Cosmos Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 roles in the Cosmos ecosystem. Engage with the Internet of Blockchains.",
      canonical: "cosmos",
      header_text: "Web3 Cosmos Jobs",
      header_sub_text: "Be part of the interchain revolution"
    },

    game_dev: {
      days: 180,
      search_query: "Game Dev",
      document_title: `Web3 Game Developer Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Game Developer roles to build immersive gaming experiences on the blockchain.",
      canonical: "game-dev",
      header_text: "Web3 Game Developer Jobs",
      header_sub_text: "Craft the future of blockchain gaming"
    },

    gpt: {
      days: 180,
      search_query: "GPT",
      document_title: `Web3 GPT Developer Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 roles focusing on the GPT architecture and its applications in the decentralized world.",
      canonical: "gpt",
      header_text: "Web3 GPT Developer Jobs",
      header_sub_text: "Harness the power of generative models in Web3"
    },

    unity: {
      days: 180,
      search_query: "Unity",
      document_title: `Web3 Unity Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 Unity Developer roles to build 3D and VR experiences for the decentralized ecosystem.",
      canonical: "unity",
      header_text: "Web3 Unity Jobs",
      header_sub_text: "Immerse users in the Web3 dimension"
    },

    prompt_engineering: {
      days: 180,
      search_query: "Prompt Engineering",
      document_title: `Web3 Prompt Engineering Jobs - ${currentMonthAbbreviation} 2023`,
      description: "Web3 roles in Prompt Engineering, leveraging language models to drive decentralized applications.",
      canonical: "prompt-engineering",
      header_text: "Web3 Prompt Engineering Jobs",
      header_sub_text: "Craft prompts that power the Web3 space"
    }
};


  return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={
            <OfferPage is_mobile={is_mobile} special_params={params['base']} />
          }/>

          <Route path="/web3-jobs" element={
            <OfferPage is_mobile={is_mobile} special_params={params['web3_jobs']}/>
          }/>

          <Route path="/internship" element={
            <OfferPage is_mobile={is_mobile} special_params={params['internship']}
                       router_fix={{value: "internship", type: "job_type"}}/>
          }/>

          <Route path="/defi" element={
            <OfferPage is_mobile={is_mobile} special_params={params['defi']}
                       router_fix={{value: "defi", type: "search_query"}}/>
          }/>

          <Route path="/nft" element={
            <OfferPage is_mobile={is_mobile} special_params={params['nft']}
                       router_fix={{value: "nft", type: "search_query"}}/>
          }/>
          <Route path="/jobs-sydney" element={
            <OfferPage is_mobile={is_mobile} special_params={params['web3_jobs_sydney']}
                       router_fix={{value: "Sydney", type: "search_location_query"}}/>
          }/>

          <Route path="/remote" element={
            <OfferPage is_mobile={is_mobile} special_params={params['remote']}
                       router_fix={{value: "true", type: "remote"}}/>
          }/>

          <Route path="/solidity" element={
            <OfferPage is_mobile={is_mobile} special_params={params['solidity']}
                       router_fix={{value: "solidity", type: "search_query"}}/>
          }/>

          <Route path="/community-manager" element={
            <OfferPage is_mobile={is_mobile} special_params={params['community_manager']}
                       router_fix={{value: "Community Manager", type: "search_query"}}/>
          }/>

          <Route path="/marketing" element={
            <OfferPage is_mobile={is_mobile} special_params={params['marketing']}
                       router_fix={{value: "Marketing", type: "search_query"}}/>
          }/>

          <Route path="/sales" element={
            <OfferPage is_mobile={is_mobile} special_params={params['sales']}
                       router_fix={{value: "Sales", type: "search_query"}}/>
          }/>

          <Route path="/design" element={
            <OfferPage is_mobile={is_mobile} special_params={params['design']}
                       router_fix={{value: "Design", type: "search_query"}}/>
          }/>

          <Route path="/engineering" element={
            <OfferPage is_mobile={is_mobile} special_params={params['engineering']}
                       router_fix={{value: "Engineering", type: "search_query"}}/>
          }/>

          <Route path="/dao" element={
            <OfferPage is_mobile={is_mobile} special_params={params['dao']}
                       router_fix={{value: "Dao", type: "search_query"}}/>
          }/>

          <Route path="/copywriter" element={
            <OfferPage is_mobile={is_mobile} special_params={params['copywriter']}
                       router_fix={{value: "Copywriter", type: "search_query"}}/>
          }/>

          <Route path="/finance" element={
            <OfferPage is_mobile={is_mobile} special_params={params['finance']}
                       router_fix={{value: "Finance", type: "search_query"}}/>
          }/>

          <Route path="/product-manager" element={
            <OfferPage is_mobile={is_mobile} special_params={params['product_manager']}
                       router_fix={{value: "Product Manager", type: "search_query"}}/>
          }/>

          <Route path="/rust" element={
            <OfferPage is_mobile={is_mobile} special_params={params['rust']}
                       router_fix={{value: "Rust", type: "search_query"}}/>
          }/>

          {/*<Route path="/non-tech" element={*/}
          {/*  <OfferPage is_mobile={is_mobile} special_params={params['non_tech']}/>*/}
          {/*}/>*/}

          <Route path="/compliance" element={
            <OfferPage is_mobile={is_mobile} special_params={params['compliance']}
                       router_fix={{value: "Compliance", type: "search_query"}}/>
          }/>

          <Route path="/content-writer" element={
            <OfferPage is_mobile={is_mobile} special_params={params['content_writer']}
                       router_fix={{value: "Content Writer", type: "search_query"}}/>
          }/>

          <Route path="/social-media" element={
            <OfferPage is_mobile={is_mobile} special_params={params['social_media']}
                       router_fix={{value: "Social Media", type: "search_query"}}/>
          }/>

          <Route path="/trading" element={
            <OfferPage is_mobile={is_mobile} special_params={params['trading']}
                       router_fix={{value: "Trading", type: "search_query"}}/>
          }/>

          <Route path="/ethereum" element={
            <OfferPage is_mobile={is_mobile} special_params={params['ethereum']}
                       router_fix={{value: "Ethereum", type: "search_query"}}/>
          }/>

          <Route path="/legal" element={
            <OfferPage is_mobile={is_mobile} special_params={params['legal']}
                       router_fix={{value: "Legal", type: "search_query"}}/>
          }/>

          <Route path="/freelance" element={
            <OfferPage is_mobile={is_mobile} special_params={params['freelance']}
                       router_fix={{value: "Freelance", type: "job_type"}}/>
          }/>

          <Route path="/part-time" element={
            <OfferPage is_mobile={is_mobile} special_params={params['part_time']}
                       router_fix={{value: "Part-Time", type: "job_type"}}/>
          }/>

          <Route path="/full-time" element={
            <OfferPage is_mobile={is_mobile} special_params={params['full_time']}
                       router_fix={{value: "Full-Time", type: "job_type"}}/>
          }/>

          <Route path="/python" element={
            <OfferPage is_mobile={is_mobile} special_params={params['python']}
                       router_fix={{value: "Python", type: "search_query"}}/>
          }/>

          <Route path="/avalanche" element={
            <OfferPage is_mobile={is_mobile} special_params={params['avalanche']}
                       router_fix={{value: "Avalanche", type: "search_query"}}/>
          }/>

          <Route path="/volunteer" element={
            <OfferPage is_mobile={is_mobile} special_params={params['volunteer']}
                       router_fix={{value: "Voluntee", type: "job_type"}}/>
          }/>

          <Route path="/polygon" element={
            <OfferPage is_mobile={is_mobile} special_params={params['polygon']}
                       router_fix={{value: "Polygon", type: "search_query"}}/>
          }/>

          <Route path="/exchange" element={
            <OfferPage is_mobile={is_mobile} special_params={params['exchange']}
                       router_fix={{value: "Exchange", type: "search_query"}}/>
          }/>

          <Route path="/mining" element={
            <OfferPage is_mobile={is_mobile} special_params={params['mining']}
                       router_fix={{value: "Mining", type: "search_query"}}/>
          }/>

          <Route path="/react" element={
            <OfferPage is_mobile={is_mobile} special_params={params['react']}
                       router_fix={{value: "React", type: "search_query"}}/>
          }/>

          <Route path="/frontend" element={
            <OfferPage is_mobile={is_mobile} special_params={params['frontend']}
                       router_fix={{value: "Frontend", type: "search_query"}}/>
          }/>

          <Route path="/ruby" element={
            <OfferPage is_mobile={is_mobile} special_params={params['ruby']}
                       router_fix={{value: "Ruby", type: "search_query"}}/>
          }/>

          <Route path="/translator" element={
            <OfferPage is_mobile={is_mobile} special_params={params['translator']}
                       router_fix={{value: "Translator", type: "search_query"}}/>
          }/>

          <Route path="/java" element={
            <OfferPage is_mobile={is_mobile} special_params={params['java']}
                       router_fix={{value: "Java", type: "search_query"}}/>
          }/>

          <Route path="/bnb-chain" element={
            <OfferPage is_mobile={is_mobile} special_params={params['bnb_chain']}
                       router_fix={{value: "BNB Chain", type: "search_query"}}/>
          }/>

          <Route path="/startup" element={
            <OfferPage is_mobile={is_mobile} special_params={params['startup']}
                       router_fix={{value: "Startup", type: "search_query"}}/>
          }/>

          <Route path="/content-marketing" element={
            <OfferPage is_mobile={is_mobile} special_params={params['content_marketing']}
                       router_fix={{value: "Content Marketing", type: "search_query"}}/>
          }/>

          <Route path="/web3py" element={
            <OfferPage is_mobile={is_mobile} special_params={params['web3_py']}
                       router_fix={{value: "Web3.py", type: "search_query"}}/>
          }/>

          <Route path="/web3js" element={
            <OfferPage is_mobile={is_mobile} special_params={params['web3_js']}
                       router_fix={{value: "Web3.js", type: "search_query"}}/>
          }/>

          <Route path="/data-analyst" element={
            <OfferPage is_mobile={is_mobile} special_params={params['data_analyst']}
                       router_fix={{value: "Data Analyst", type: "search_query"}}/>
          }/>

          <Route path="/fantom" element={
            <OfferPage is_mobile={is_mobile} special_params={params['fantom']}
                       router_fix={{value: "Fantom", type: "search_query"}}/>
          }/>

          <Route path="/devops" element={
            <OfferPage is_mobile={is_mobile} special_params={params['devops']}
                       router_fix={{value: "Devops", type: "search_query"}}/>
          }/>

          <Route path="/business-development" element={
            <OfferPage is_mobile={is_mobile} special_params={params['business_development']}
                       router_fix={{value: "Business Development", type: "search_query"}}/>
          }/>

          <Route path="/cosmos" element={
            <OfferPage is_mobile={is_mobile} special_params={params['cosmos']}
                       router_fix={{value: "Cosmos", type: "search_query"}}/>
          }/>

          <Route path="/game-dev" element={
            <OfferPage is_mobile={is_mobile} special_params={params['game_dev']}
                       router_fix={{value: "Game Dev", type: "search_query"}}/>
          }/>

          <Route path="/gpt" element={
            <OfferPage is_mobile={is_mobile} special_params={params['gpt']}
                       router_fix={{value: "GPT", type: "search_query"}}/>
          }/>

          <Route path="/unity" element={
            <OfferPage is_mobile={is_mobile} special_params={params['unity']}
                       router_fix={{value: "Unity", type: "search_query"}}/>
          }/>

          <Route path="/prompt-engineering" element={
            <OfferPage is_mobile={is_mobile} special_params={params['prompt_engineering']}
                       router_fix={{value: "Prompt Engineering", type: "search_query"}}/>
          }/>

          {/*--------------------------------------------------------------------------------------------*/}
          {/*--------------------------------------------------------------------------------------------*/}
          {/*--------------------------------------------------------------------------------------------*/}

          <Route path="about" element={
            <Layout is_mobile={is_mobile} special_params={params['about']}>
              <About special_params={params['about']}/>
            </Layout>
          }/>

          <Route path="job-post" element={
            <Layout is_mobile={is_mobile} special_params={params['job_post']}>
              <JopPost is_mobile={is_mobile}/>
            </Layout>
          }/>

          <Route path="contact" element={
            <Layout is_mobile={is_mobile} special_params={params['contact']}>
              <Contact is_mobile={is_mobile}/>
            </Layout>
          }/>

          <Route path="terms-of-service" element={
            <Layout is_mobile={is_mobile} special_params={params['terms_of_service']}>
              <Tos is_mobile={is_mobile}/>
            </Layout>
          }/>

          <Route path="offer/:offer_id/:offer_title?" element={
            <Layout is_mobile={is_mobile}>
              <SingleOffer is_mobile={is_mobile}/>
            </Layout>
          }/>

          <Route path="job-delete/:uuid" element={
            <Layout is_mobile={is_mobile} special_params={params['job_delete']}>
              <JobDelete is_mobile={is_mobile}/>
            </Layout>
          }/>

          <Route path="*" element={
            <Navigate to="/" replace />
          }/>

        </Routes>
      </BrowserRouter>
  );
}

createRoot(document.querySelector('.container')).render(<App />);
