import React, {useEffect, useState} from 'react';
import { Header, Grid, Icon, Button, Message } from "semantic-ui-react"

import { get_offer_card_main } from "../components/offer_cards";
import { useParams } from "react-router-dom";

const JobDelete = ({ is_mobile }) => {
  const { uuid } = useParams()
  const [offer_data, setOfferData] = useState({})
  const [not_found_error, setNotFoundError] = useState(false)
  const [delete_error, setDeleteError] = useState(false)
  const [success, setSuccess] = useState(false)

  const deleteOffer = () => {
    fetch(`${process.env.REACT_APP_BASE_URL}/job-delete/${uuid}`, {method: 'post'})
        .then((res) => {
          if (res.status === 200) {
            setSuccess(true)
          }
          else {
            setDeleteError(true)
          }
        })
  }

  useEffect(() => {
    if (uuid) {
      fetch(`${process.env.REACT_APP_BASE_URL}/check-uuid/${uuid}`)
          .then((res) => {
            if (res.status === 404) {
              setNotFoundError(true)
              return {}
            }
            else {
              return res.json()
            }
          })
          .then((json) => {
            if (json !== {}) {
              setOfferData(json)
            }
          })
    }
  }, [])

  if (Object.keys(offer_data).length !== 0 && !success && !delete_error) {
    return (
        <>
        <Grid id="delete-offer-grid" container>
          <Grid.Row>
            <Grid.Column width={is_mobile ? null : 2}/>
            <Grid.Column width={is_mobile ? 16 : 12}>
              {Object.keys(offer_data).length !== 0 && get_offer_card_main(offer_data, is_mobile)}
              <Header as="h1" textAlign="center"> Are you sure you want to delete this job?</Header>
              <Button
                  onClick={deleteOffer}
                  style={{color: "white", backgroundColor: "red"}}
                  size="big"
                  fluid>
                <Icon name="remove"/>
                Delete offer
              </Button>
              <Button
                  style={{color: "white"}}
                  id="apply-button"
                  size="big"
                  fluid>
                <Icon name="arrow left"/>
                Cancel
              </Button>
            </Grid.Column>
            <Grid.Column width={is_mobile ? null : 2}/>
          </Grid.Row>
        </Grid>
          </>
    )
  }
  else if (not_found_error) {
    return (
        <Message negative style={{textAlign: "center"}}>
          <Message.Header>Link invalid</Message.Header>
          <p>If this is incorrect, please contact us at contact@tokenjobs.io</p>
        </Message>
    )
  }
  else if (success) {
    return (
        <Message positive style={{textAlign: "center"}}>
          <Message.Header>Job removed</Message.Header>
          <p>Your job offer was successfully removed</p>
        </Message>
    )
  }
  else if (delete_error) {
    return (
        <Message negative style={{textAlign: "center"}}>
          <Message.Header>Job removal error</Message.Header>
          <p>There was an error during the removal of your job offer.
            Please contact us at contact@tokenjobs.io if this was incorrect</p>
        </Message>
    )
  }
}

export default JobDelete;