import {Button, Icon} from "semantic-ui-react";
import React from "react";

const JobPostButton = ({style, className, labelPosition}) => {
  return (
      <Button
          className={className}
          style={style}
          labelPosition={labelPosition ? labelPosition : 'left'}
          icon={<Icon name="right arrow"/>}
          id='job-post-button'
          compact
          content='Post a Job'
          basic color='purple'
          href="/job-post"
          />
  )
}


export default JobPostButton;