import React from 'react'
import { Checkbox } from 'semantic-ui-react'

const CheckToggle = (props) => {
  return (
    <Checkbox
        defaultChecked={props.query[props.type]}
        onChange={(e, data) => {
          const types = {title: {title: data.checked}, remote: {remote: data.checked}}
          props.setQuery(previousState => {
            return Object.assign({}, { ...previousState, page: 0}, types[props.type]);
          }
          )}}
    />
  )
}

export default CheckToggle