import React from "react";
import {Container} from "semantic-ui-react";

const GetLogos = ({offers}) => {
  const uniqueOffers = offers.filter((logo, index, self) => {
    return (
        index === self.findIndex((l) => l.logo === logo.logo)
    )
  })

  return uniqueOffers.map((offer, index) => {
    if (offer.logo) {
      return (<img key={`logo-${index}`} src={offer.logo} alt={`${offer.company_name} logo`}/>)
    }
  })
}

const LogoSlideshow = ({offers, is_mobile}) => {
  return (
      <Container text>
        <div className="slideshow-container">
          <div className={"logo-container" + (is_mobile ? " mobile" : "")}>
            <GetLogos offers={offers}/>
          </div>
        </div>
      </Container>
  );
};

export default LogoSlideshow