import { useEffect } from "react";

function filter_search_params(search_query, title, days, remote, job_type, language, search_location_query, special_params) {
  let final_params = {}

  if (search_query && (!('search_query' in special_params) || ('search_query' in special_params && special_params['search_query'] !== search_query) )) {
    final_params['search_query'] = search_query
  }

  if (job_type && !('job_type' in special_params)) {
    final_params['job_type'] = job_type
  }

  if (language) {
    final_params['language'] = language
  }

  if (search_location_query) {
    final_params['search_location_query'] = search_location_query
  }

  if (title === true) {
    final_params['title'] = title
  }

  if (remote === true) {
    final_params['remote'] = remote
  }

  if (+days !== 30 && +days !== 180) {
    final_params['days'] = days
  }

  return final_params
}


const useFetchOffers = (
    setOfferData,
    setSearchParams,
    page,
    search_query="",
    title=false,
    days= 30,
    remote,
    job_type,
    language,
    search_location_query,
    special_params = {}
) => {

  useEffect(() => {
    setSearchParams(filter_search_params(search_query, title, days, remote, job_type, language, search_location_query, special_params))
    console.log("request effect")
    setOfferData({offers: [], featured_offers: [], count: 0, offer_loading: true});

    const limit = 100;
    let skip = page * limit;

    let fetch_url = `${process.env.REACT_APP_BASE_URL}/offers?skip=${skip}&limit=${limit}&search_query=${search_query}` +
    `&title=${title}&days=${days}&remote=${remote}&job_type=${job_type}&language=${language}` +
    `&search_location_query=${search_location_query}`
    fetch(fetch_url)
        .then((res) => res.json())
        .then((json) => {
          setOfferData(Object.assign({}, json, {offer_loading: false}))
        })
  }, [page, search_query, title, days, remote, job_type, language, search_location_query]);
}

export default useFetchOffers;