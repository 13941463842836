import {Label} from "semantic-ui-react";
import React from "react";


const SearchLabels = ({query, setQuery}) => {
  let values = [{search_query: ''}, {language: ''}, {job_type: ''}, {search_location_query: ''}]
  // {apply_type: ''}
  let contents = [query.search_query, query.language, query.job_type, query.search_location_query]
  // query.apply_type

  return values.map((value, index) => {
    if (contents[index] !== '') {
      return (
          <Label
              style={{marginRight: 2, marginLeft: 0, marginTop: 4}} color="violet" as='a'
              content={contents[index]}
              key={index}
              onRemove={(e, v) => {
                setQuery(previousState => {
                  return Object.assign({}, {...previousState, page: 0}, value)
                })
              }}>
          </Label>
      )
    }
    else {
      return null
    }
  })
}

export default SearchLabels