import Header from './header';
import Footer from "./footer";

import React from "react";
import {Helmet} from "react-helmet";

const Layout = (props) => {
  return (
      <div className="main-content" style={{backgroundColor: '#fafafd'}}>
        {props.special_params &&
            <Helmet>
              <meta name="description" content={props.special_params['description']}/>
              <meta property="og:description" content={props.special_params['description']}/>
              <meta property="og:title" content={props.special_params['document_title']}/>
              <title>{props.special_params['document_title']}</title>
              {'canonical' in props.special_params &&
                  <link rel="canonical" href={`${process.env.REACT_APP_PRINCIPAL_URL}/${props.special_params['canonical']}`}/>
                  &&
                  <meta property="og:url" content={`${process.env.REACT_APP_PRINCIPAL_URL}/${props.special_params['canonical']}`}/>
              }
            </Helmet>
        }
        <Header is_mobile={props.is_mobile} header_data={props.header_data}/>
        <div>{props.children} </div>
        <div style={{marginBottom: '2em'}}></div>
        <Footer offer_loading={props.offer_loading} is_mobile={props.is_mobile}/>
      </div>
  );
};

export default Layout;