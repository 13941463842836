import React from 'react'
import { Icon, Step } from 'semantic-ui-react'

const JobPostSteps = ({status, setStatus}) => (
  <Step.Group unstackable fluid id="job-post-steps">
    <Step onClick={() => status !== 'success' ? setStatus("details") : null} active>
      <Icon name='info' />
      <Step.Content>
        <Step.Title>Details</Step.Title>
      </Step.Content>
    </Step>

    {/* If different than details then of course its preview or confirm so it should be active*/}
    <Step onClick={() => status === "confirm" ? setStatus("preview") : null} active={status !== "details"} disabled={status === "details" }>
      <Icon name='magnify' />
      <Step.Content>
        <Step.Title>Preview</Step.Title>
      </Step.Content>
    </Step>

    <Step active={status === "confirm" || status === 'success'} disabled={status !== "confirm" && status !== 'success'}>
      <Icon name='paper plane' />
      <Step.Content>
        <Step.Title>Confirm</Step.Title>
      </Step.Content>
    </Step>
  </Step.Group>
)

export default JobPostSteps