import React, {Component } from 'react';
import {Icon, Container, Menu, Segment, Header, Sidebar, Image, Grid} from 'semantic-ui-react';

import JobPostButton from "./job_post_button";

const DESKTOP_LOGO_URL = 'https://static-images123.s3.eu-west-3.amazonaws.com/transparent_logo_and_name_header.png'
const MOBILE_LOGO_URL = 'https://static-images123.s3.eu-west-3.amazonaws.com/logo.png'

function getDesktopHeaderMenu() {
  return (
      <Menu id="desktopHeaderMenu" inverted secondary size='large' style={{marginTop: '0em'}}>
        <Container text className="header-container">
        <Grid container>
          <Grid.Row centered>
            <Grid.Column width={4} floated='left' style={{marginTop: '0.2em'}}>
              <Image alt="website logo" fluid src={DESKTOP_LOGO_URL} href={process.env.REACT_APP_PRINCIPAL_URL}/>
            </Grid.Column>
            <Grid.Column width={2}>
              <Menu.Item link href='/' as='a' >Jobs</Menu.Item>
            </Grid.Column>
            <Grid.Column width={2}>
              <Menu.Item link href='/about' as='a' >About</Menu.Item>
            </Grid.Column>
            <Grid.Column width={2}>
              <Menu.Item link href='/contact' as='a' >Contact</Menu.Item>
            </Grid.Column>
            <Grid.Column width={2}>
              <Menu.Item link href='/terms-of-service' as='a' >Terms</Menu.Item>
            </Grid.Column>
            <Grid.Column textAlign='right' width={3} floated="right" verticalAlign="middle">
              <JobPostButton/>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        </Container>
      </Menu>
  )
}

function getMobileHeaderMenu(sidebarOpened, handleSidebarHide, handleToggle) {
  const sidebarOpenedHeight = '8.5em'
  return (
      <Sidebar.Pushable>
        <Sidebar
            id="sidebar-opened-mobile-header"
            as={Menu} animation='overlay'
            onHide={handleSidebarHide}
            visible={sidebarOpened}
            vertical
            width='thin'>
          <Menu.Item link href='/' as='a'>Jobs</Menu.Item>
          <Menu.Item link href='/about' as='a'>About</Menu.Item>
          <Menu.Item link href='/contact' as='a'>Contact</Menu.Item>
          <Menu.Item link href='/terms-of-service' as='a'>Terms</Menu.Item>
        </Sidebar>

        <Sidebar.Pusher style={{minHeight: sidebarOpened ? sidebarOpenedHeight: 0}}>
          <Menu inverted pointing secondary size='huge'>
            <Menu.Item style={{paddingBottom: null}} onClick={handleToggle}>
              <Icon size='large' name='sidebar'/>
            </Menu.Item>
            <Menu.Item position='right' link href={process.env.REACT_APP_PRINCIPAL_URL} style={{paddingRight: 5, paddingTop: 0, paddingBottom: 0, paddingLeft: 0}}>
              <Image alt="website logo" size='tiny' src={MOBILE_LOGO_URL} style={{width: '5em'}}/>
            </Menu.Item>
          </Menu>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
  )
}


class HeaderComponent extends Component {
    state = {}

    handleSidebarHide = () => this.setState({ sidebarOpened: false })

    handleToggle = () => this.setState({ sidebarOpened: true })

    render() {
      const {sidebarOpened} = this.state
      let is_mobile  = this.props.is_mobile;
      let header_menu = is_mobile ? getMobileHeaderMenu(sidebarOpened, this.handleSidebarHide, this.handleToggle) : getDesktopHeaderMenu()

      let base_header_text = is_mobile ? <><h1 style={{fontSize: "2rem"}}> <span style={{color:"#ffffff"}}>The largest job board of Crypto <br/> Blockchain and Web3 jobs </span> </h1></> :
          <><h1 style={{fontSize: '4rem', whiteSpace: 'pre-line'}}><span style={{color:"#ffffff", whiteSpace: 'pre-line'}}>The largest job board of Crypto <br/> Blockchain and Web3 jobs </span> </h1></>

      return (
          <div>
          <Segment id="segment-header-footer" className={`header-main${is_mobile ? "" : "-desktop"}`} inverted vertical textAlign='center'
                   style={{padding: '0em 0em', minHeight: is_mobile? 120 : 100, marginBottom: '2em'}}>
            {header_menu}
            <div style={{paddingTop: is_mobile ? '2em' : '5em', paddingBottom: is_mobile ? '2em' : '13em'}}>
              <Header
                  textAlign="center"
                  content={this.props.header_data ? <><h1 style={{fontSize: '4rem'}}><span style={{color:"#ffffff"}}>{this.props.header_data['header_text']}</span></h1>  <h2 style={{fontSize: '2rem'}}><span style={{color:"#ffffff"}}>&nbsp;{this.props.header_data['header_sub_text']}</span> </h2></> : base_header_text}
                  style={{fontSize: '0em', fontWeight: 'bold'}}/>
              <Header inverted style={{marginBottom: 0, marginTop: 0, marginLeft: '0.5em'}}>
                <h2 id="custom-h2" style={{fontSize: is_mobile ? '1.71rem' : '2.31rem'}}>
                  <span id="before-after-word">All </span>
                  <span id="words-list">
                    <span className="web3 jobs" id={"text_1" + (is_mobile ? "_mobile" : "")}>web3&nbsp;</span>
                    <span className="nft jobs" id={"text_2" + (is_mobile ? "_mobile" : "")}>nft&nbsp;</span>
                    <span className="defi jobs" id={"text_3" + (is_mobile ? "_mobile" : "")}>defi&nbsp;</span>
                    <span className="crypto jobs" id={"text_4" + (is_mobile ? "_mobile" : "")}>crypto&nbsp;</span>
                    <span className="blockchain jobs" id={"text_5" + (is_mobile ? "_mobile" : "")}>blockchain&nbsp;</span>
                    <span id={"text_6" + (is_mobile ? "_mobile" : "")}>token</span>
                  </span>
                  <span id="placeholder-caret">  &nbsp;</span>
                  <span id="before-after-word">&nbsp;jobs in one place</span>
                </h2>
              </Header>
              {is_mobile ? <JobPostButton labelPosition="right" className="mobile-job-post-button"/> : null}
            </div>
          </Segment>
          </div>
      );
    }
}


export default HeaderComponent;