import _ from 'lodash'
import React, {useState} from 'react'
import { Search, Icon } from 'semantic-ui-react'

const initialState = {
  loading: false,
  results: [],
  value: ''
}

function searchReducer(state, action) {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return initialState
    case 'START_SEARCH':
      return { ...state, loading: action.query.length > 1, value: action.query }
    case 'FINISH_SEARCH':
      return { ...state, loading: false, results: action.results }
    case 'UPDATE_SELECTION':
      return { ...state, value: action.selection }

    default:
      throw new Error()
  }
}

function check_results(dispatch, json, search_value) {
  if (json.length === 0) {
    json = [{title: search_value}, {title: "web3"}, {title: "solidity"}, {title: "nft"}, {title: "community manager"}, {title: "discord"}]
  }
  else {
    json = json.map((skill) => {
      return {title: skill.name}
    })
  }
  dispatch({type: 'FINISH_SEARCH', results: json});
}

let debounced = _.debounce(function (callback) {return callback();}, 500);

const SearchBar = (props) => {
  const [state, dispatch] = React.useReducer(searchReducer, initialState)
  const { loading, results, value } = state
  const [open, setOpen] = useState(false)

    const handleKeyDown = (event) => {
    if (event.charCode === 13 && (value.length === 0 || value.length > 1)) {
      // dispatch({ type: 'UPDATE_SELECTION', selection: value})
      props.setQuery(previousState => {
        return { ...previousState, search_query: value, page: 0 }
      });
      setOpen(false)
    }
  }

  const timeoutRef = React.useRef()
  const handleSearchChange = React.useCallback((e, data) => {
    clearTimeout(timeoutRef.current)
    setOpen(true)
    dispatch({type: 'START_SEARCH', query: data.value})

    timeoutRef.current = setTimeout(() => {
      if (data.value.length === 0) {
        dispatch({type: 'CLEAN_QUERY'})
        return
      }

      if (data.value.length > 1) {
        debounced(function () {
          fetch(`${process.env.REACT_APP_BASE_URL}/skills/?search_query=${data.value}`)
              .then((res) => res.json())
              .then((json) => check_results(dispatch, json, data.value))
        });
      }


    }, 300)

  }, [])

  React.useEffect(() => {
    return () => {
      clearTimeout(timeoutRef.current)
    }
  }, [])

  return (
        <Search
            input={{fluid: props.is_mobile}}
            size={props.is_mobile ? 'tiny': 'small'}
            minCharacters={2}
            loading={loading}
            icon={<Icon color="purple" name="search"/>}
            placeholder='Search Jobs...'
            onResultSelect={(e, data) => {
              // dispatch({ type: 'UPDATE_SELECTION', selection: data.result.title })
              props.setQuery(previousState => {
                return { ...previousState, search_query: data.result.title, page: 0 }
              });
              setOpen(false)
            }}
            onBlur={(e, v) => {setOpen(false)}}
            onSearchChange={handleSearchChange}
            results={results}
            value={value}
            onKeyPress={handleKeyDown}
            noResultsMessage="No jobs found."
            showNoResults={false}
            open={open}
        />
  )
}

export default SearchBar;