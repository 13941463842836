import {Dimmer, Image, Loader, Segment} from "semantic-ui-react";
import React from "react";

const OfferLoading = ({offerLoading}) => {
  if (!offerLoading) {
    return
  }
  const s = [1, 2, 3, 4, 5];
  return s.map((number) => {
    return (
        <Segment key={number}>
          <Dimmer active inverted>
            <Loader inverted />
          </Dimmer>
            <Image src='https://react.semantic-ui.com/images/wireframe/short-paragraph.png' />
        </Segment>
    );
  });
}

export default OfferLoading;