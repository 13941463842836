import {Grid} from "semantic-ui-react";
import React, {useState} from "react";
import CheckToggle from "./checkbox";
import SearchBar from "./search_bar";
import FilterDropDown from "./filter_dropdown";
import SortDropDown from "../components/sort";
import SearchLabels from "./search_labels"
import SearchBarLocation from "./search_bar_location";
import CategoryNavigation from "./category_navigation";

function MobileSearchAndFilter(props, category_show_more, setCategoryShowMore) {
  return (
      <Grid container id="mobile-search-and-filter-grid">
        <Grid.Row id="role-row" columns={2}>
          <Grid.Column className="jobs-search-bar" width={8} floated="left">
            <SearchBar is_mobile={true} setQuery={props.setQuery}/>
          </Grid.Column>
          <Grid.Column className="jobs-search-bar" width={8} floated="right">
            <SearchBarLocation is_mobile={true} setQuery={props.setQuery}/>
          </Grid.Column>
        </Grid.Row>

        <Grid.Row id='filter-row' columns={2}>
          <Grid.Column width={2} className="toggle-thing mobile" >
              <div className="toggle-text">Remote</div>
            <CheckToggle type={"remote"} query={props.query} setQuery={props.setQuery}/>
          </Grid.Column>
          <Grid.Column width={4} className="toggle-thing mobile right">
            <div className="toggle-text">in Title</div>
            <CheckToggle type={"title"} query={props.query} setQuery={props.setQuery}/>
          </Grid.Column>
          <Grid.Column width={6} className="filter-grid-column mobile" verticalAlign="middle" floated="right" textAlign="right">
            <div className="filter-drop mobile"><FilterDropDown setQuery={props.setQuery}/> </div>
          </Grid.Column>
        </Grid.Row>

        <CategoryNavigation is_mobile={props.is_mobile} category_show_more={category_show_more} setCategoryShowMore={setCategoryShowMore}/>

        <SearchLabels query={props.query} setQuery={props.setQuery}/>

        <Grid.Row id="result-count-and-day-filter-grid-row" columns={2}>
          <Grid.Column id="result-count-grid-column" style={{fontWeight: 10}}>
            {props.offers_total_count } <span>&nbsp;jobs</span>
          </Grid.Column>

          <Grid.Column id="mobile-result-count-and-day-filter-grid-right" textAlign="right">
            <SortDropDown query={props.query} setQuery={props.setQuery}/>
          </Grid.Column>
        </Grid.Row>
      </Grid>
  )
}

function DesktopSearchAndFilter(props, category_show_more, setCategoryShowMore) {
  return (
      <Grid id="desktop-search-and-filter-grid" container columns={2}>
        <Grid.Row columns={5} id="desktop-role-filter-row">

          <Grid.Column width={2} className="toggle-thing desktop" textAlign="left">
            <div className="toggle-text">Remote</div>
            <CheckToggle type={"remote"} query={props.query} setQuery={props.setQuery}/>
          </Grid.Column>
          <Grid.Column width={2} className="toggle-thing desktop" textAlign="left">
            <div className="toggle-text">in Title</div>
            <CheckToggle type={"title"} query={props.query} setQuery={props.setQuery} />
          </Grid.Column>

          <Grid.Column verticalAlign="middle" width={3} className="filter-grid-column desktop role">
            <div className="filter-drop desktop"><FilterDropDown setQuery={props.setQuery}/></div>
          </Grid.Column>

          <Grid.Column className="jobs-search-bar" width={4} floated="right" style={{paddingLeft: 0}}>
            <SearchBarLocation isMobile={false} setQuery={props.setQuery}/>
          </Grid.Column>

          <Grid.Column className="jobs-search-bar" width={4} textAlign="right" floated="right" style={{paddingLeft: 0}}>
            <SearchBar isMobile={false} setQuery={props.setQuery}/>
          </Grid.Column>

        </Grid.Row>


        <CategoryNavigation is_mobile={props.is_mobile} category_show_more={category_show_more} setCategoryShowMore={setCategoryShowMore}/>


        <SearchLabels query={props.query} setQuery={props.setQuery}/>

        <Grid.Row id="result-count-and-day-filter-grid-row" columns={2}>
          <Grid.Column id="result-count-grid-column" style={{fontWeight: 10}}>
            {props.offers_total_count } <span>&nbsp;jobs</span>
          </Grid.Column>

          <Grid.Column textAlign="right">
            <SortDropDown query={props.query} setQuery={props.setQuery}/>
          </Grid.Column>
        </Grid.Row>
      </Grid>
  )
}


const SearchAndFilter = (props) => {
  const [category_show_more, setCategoryShowMore] = useState(false)
  return (
      <div>
        {props.is_mobile ?
            MobileSearchAndFilter(props, category_show_more, setCategoryShowMore) : DesktopSearchAndFilter(props, category_show_more, setCategoryShowMore)}
      </div>
  )
}

export default SearchAndFilter;